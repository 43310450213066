import React, { Component } from 'react';
import { Provider } from "react-redux";
import { store, history } from "./redux/store";
import AppRoutes from "./routes";
import './App.css';

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Provider store={store}>
          <AppRoutes history={history} />
        </Provider>
      </React.Fragment>
    )
  }
}
