import React, { Component } from 'react';
import { Container } from "reactstrap";
import { cardImg1, cardImg2, cardImg3, cardImg4 } from "./images";
import Slider from 'react-slick';
import './style.css';

const data = [
  { title: "You enjoy wasting money like a drunk sailor", img_url: cardImg1, description: "Sorry, our competitors are way overpriced and don't have as many features. We're right in the sweet spot for value!" },
  { title: "You enjoy sending emails and wasting time waiting for a response", img_url: cardImg2, description: "Sorry, we have a small chat widget in the app that allows you to get help faster. Much faster than email." },
  { title: "You enjoy historical movies and feel like your current software isn't keeping up with the times", img_url: cardImg3, description: "Sorry, we were one of the first to offer eBay cross product listing and now a mobile app because we know where the industry is heading." },
  { title: "You enjoy gluing together a bunch of tools to just sell things on Amazon", img_url: cardImg4, description: "Sorry, we enable you to sell on Amazon and eBay and we can reprice your items for you. Done and done." }
]

class CardsBanner extends Component {
  state = {
    useSlider: false
  }

  componentDidMount = () => {
    this.adjustBanner()
    window.addEventListener('resize', this.adjustBanner)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.adjustBanner)
  }

  adjustBanner = () => {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    if(screenWidth <= 1280) {
      this.setState({ useSlider: true })
    }
  }

  render() {
    return (
      <section className="cards-banner-section">
        <Container fluid={this.state.useSlider ? false : true}>
          <div className="section-heading">
            <h2 className="section-title"><span className="text-danger">WARNING:</span> You'll hate AccelerList if …</h2>
          </div>
          {this.state.useSlider ? 
          <div className="cards-banner cards-banner-slider">
            <Slider
              slidesToScroll={1}
              slidesToShow={3}
              responsive = {[
                {
                  breakpoint: 1279,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1
                  }
                }
              ]}
            >
            {data.map((item, idx) => (
            <div
              key={`cards-banner-${idx}`}
              id={`cards-banner-${idx}`}
              className="cards-banner-item"
            >
              <h3 className="cards-banner-item-title">{item.title}</h3>
              <div className="cards-banner-item-image">
                <img src={item.img_url} alt="" />
              </div>
              <p className="cards-banner-item-description">
                {item.description}
              </p>
            </div>
            ))}
            </Slider>
          </div>
          :
          <div className="cards-banner">
            {data.map((item, idx) => (
            <div
              key={`cards-banner-${idx}`}
              id={`cards-banner-${idx}`}
              className="cards-banner-item"
            >
              <h3 className="cards-banner-item-title">{item.title}</h3>
              <div className="cards-banner-item-image">
                <img src={item.img_url} alt="" />
              </div>
              <p className="cards-banner-item-description">
                {item.description}
              </p>
            </div>
            ))}
          </div>
          }
        </Container>
      </section>
    )
  }
}

export default CardsBanner;