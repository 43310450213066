const userActions = {
	USER_GET_DATA: 'USER_GET_DATA',
	USER_GET_DATA_SUCCESS: 'USER_GET_DATA_SUCCESS',
	USER_GET_DATA_ERROR: 'USER_GET_DATA_ERROR',

	USER_SET_DATA: 'USER_SET_DATA',
	USER_SET_DATA_SUCCESS: 'USER_SET_DATA_SUCCESS',
  USER_SET_DATA_ERROR: 'USER_SET_DATA_ERROR',

	USER_SAVE_DATA: 'USER_SAVE_DATA',
	USER_SAVE_DATA_SUCCESS: 'USER_SAVE_DATA_SUCCESS',
	USER_SAVE_DATA_ERROR: 'USER_SAVE_DATA_ERROR',

  USER_UPDATE_SETTINGS: "USER_UPDATE_SETTINGS",

  userUpdateSettings: (data, saga=null) => ({
    type: userActions.USER_UPDATE_SETTINGS,
    data,
    saga
  }),

	userSaveData: (data) => ({
		type: userActions.USER_SAVE_DATA,
    data
	}),
	userSaveDataSuccess: data => ({
		type: userActions.USER_SAVE_DATA_SUCCESS,
		data
	}),
	userSaveDataError: (data) => ({
		type: userActions.USER_SAVE_DATA_ERROR,
    data
	}),

	userSetData: (data) => ({
	  type: userActions.USER_SET_DATA,
	  data
	}),
	userSetDataSuccess: (data) => ({
	  type: userActions.USER_SET_DATA_SUCCESS,
	  data
	}),
	userSetDataError: () => ({
	  type: userActions.USER_SET_DATA_ERROR,
	}),

	userGetData: () => ({
		type: userActions.USER_GET_DATA,
	}),
	userGetDataSuccess: data => ({
		type: userActions.USER_GET_DATA_SUCCESS,
		data
	}),
	userGetDataError: () => ({
		type: userActions.USER_GET_DATA_ERROR,
	}),

}

export default userActions;
