import React, { Component } from "react"
import { connect } from "react-redux"
import './style.css'
import {
  Badge,
  Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup,
  InputGroupText, Label, Pagination, PaginationItem, PaginationLink, Placeholder, Row, Toast, ToastBody
} from "reactstrap"
import Select from 'react-select'
import { Link } from "react-router-dom"
import LoginModal from "../LoginModal"
import communityActions from "../../../redux/community/actions";
import Dialog from "../../../shared/components/Dialog"
import { Helmet } from "react-helmet"

const FILE_UPLOAD_LIMIT_NUM = 5 // Megabytes
const FILE_UPLOAD_LIMIT = FILE_UPLOAD_LIMIT_NUM * 1048576

const {
  roadmapGetCategories,
  roadmapCreate,
  roadmapSearch,
  roadmapUploadImage,
  roadmapClearImage,
  adminRoadmapDelete,
} = communityActions;

const statusFilterOptions = [
  {value: null, label: 'All Status'},
  {value: 'UNDER_REVIEW', label: 'Under Review'},
  {value: 'IN_PROGRESS', label: 'In Progress'},
  {value: 'COMPLETE', label: 'Complete'},
]

const statusClassMap = {
  'UNDER_REVIEW': 'info',
  'IN_PROGRESS': 'warning',
  'COMPLETE': 'success',
}

class FeatureRequest extends Component {
  state = {
    searchFocus: false,
    search: '',
    filterStatus: {value: null, label: 'All Status'},
    filterCategory: {value: null, label: 'All Categories'},
    fileSizeError: false,
    showLoginModal: false,
    requestLoading: true,
    create_category: null,
    create_title: "",
    create_desc: {},
    deleteRequestId: null
  }

  componentDidMount = () => {
    setTimeout(() => this.setState({ requestLoading: false }), 2000)
    this.props.roadmapGetCategories();
    this.props.roadmapSearch();
  }

  onImageUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const uploadedFiles = e.target.files;

    let timer = null
    if(uploadedFiles[0]) {
      if(uploadedFiles[0].size > FILE_UPLOAD_LIMIT) {
        this.setState({ fileSizeError: true })
        timer = setTimeout(() => {
          this.setState({ fileSizeError: false })
          clearTimeout(timer)
        }, 4000)
      } else {
        // upload image and set the URL for preview
        var formData = new FormData();
        formData.append(
          "file",
          uploadedFiles[0]
        );
        this.props.roadmapUploadImage(formData);
      }
    }
  }

  generateCategries = () => {
    const { roadmap_categories } = this.props;
    if(roadmap_categories){
      return roadmap_categories.map(item => {
        return {value: item.id, label: item.name}
      })
    }

    return [];
  }

  render() {
    const {
      searchFocus,
      search,
      filterStatus,
      filterCategory,
      fileSizeError,
      showLoginModal,
      deleteRequestId
    } = this.state

    const {
      userData,
      roadmap_working,
      roadmaps,
      roadmaps_loading,
      roadmap_image,
      roadmap_image_loading,
    } = this.props

    return (
      <div className="feature-request">
        <Helmet>
          <title>Feature Roadmap | AccelerList</title>
          <meta name="description" content="AccelerList Feature Roadmap" />
        </Helmet>
        <Row>
          <Col md={4}>
          <Card className="feature-request-sidebar">
            <CardBody>
              <div className="text-center">
                <h3 className="h6 card-title">Submit a feature request</h3>
                {userData.username ?
                <p>Please search existing requests first before submitting to prevent duplicate requests.</p>
                :
                <React.Fragment>
                  <p>Please login to submit a feature request.</p>
                  <Button color="primary" onClick={() => this.setState({ showLoginModal: true })}>Login to community</Button>
                </React.Fragment>
                }
              </div>
              {userData.username ?
              <Form>
                <FormGroup>
                  <Label>Pick a category:</Label>
                  <Select
                    options={this.generateCategries()}
                    onChange={(e) => this.setState({create_category: e})}
                    value={this.state.create_category}
                    disabled={roadmap_working}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Give the question a Title:</Label>
                  <Input
                    placeholder="Short and descriptive title"
                    onChange={(e) => this.setState({create_title: e.target.value})}
                    value={this.state.create_title}
                    disabled={roadmap_working}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Describe the problem:</Label>
                  <Input
                    type="textarea"
                    placeholder="Details of request"
                    rows="5"
                    disabled={roadmap_working}
                    value={this.state.category_desc}
                    onChange={(e) => this.setState({category_desc: e.target.value})}
                  />
                </FormGroup>
                {roadmap_image &&
                <div className="request-uploaded-image" key={`uploaded-image`}>
                  <img src={roadmap_image} alt="" width={100} />
                  <span
                    className="request-uploaded-image-delete"
                    role="button"
                    onClick={() => console.log('delete this image')}
                  >&#x2715;</span>
                </div>
                }
                <div className="hstack justify-content-between">
                  <div>
                    {!roadmap_image &&
                    <React.Fragment>
                    <span
                      className="icon icon-18 icon-image"
                      role="button"
                      onClick={() => {
                        this.upload.click()
                      }}
                    />
                    <input
                      type="file"
                      id="imageFile"
                      className="d-none"
                      ref={(ref) => this.upload = ref}
                      accept={"image/png, image/jpeg"}
                      onChange={this.onImageUpload.bind(this)}
                    />
                    </React.Fragment>
                    }
                  </div>
                  <Button
                    color="primary"
                    onClick={() => {
                      this.props.roadmapCreate({
                        title: this.state.create_title,
                        id_category: this.state.create_category?.value,
                        description: this.state.category_desc || "",
                        image_url: roadmap_image || null,
                      })
                      this.setState({
                        create_category: null,
                        create_title: "",
                        category_desc: "",
                      })
                      this.props.roadmapClearImage();
                    }}
                    disabled={!this.state.create_title
                        || !this.state.create_category
                        || roadmap_working
                        || roadmap_image_loading
                    }
                  >Submit</Button>
                </div>
              </Form>
              : null}
            </CardBody>
          </Card>
          </Col>
          <Col md={8}>
            <Card className="feature-request-main">
              <CardBody className="feature-request-filters">
                {!searchFocus &&
                <React.Fragment>
                <div style={{ minWidth: '11rem' }}>
                  <Select
                    placeholder="Filter status"
                    options={statusFilterOptions}
                    value={filterStatus}
                    onChange={(e) => {
                      this.props.roadmapSearch({
                        search: this.state.search,
                        category: this.state.filterCategory?.value ? this.state.filterCategory?.value : null,
                        status: e ? e.value : null,
                      })
                      this.setState({ filterStatus: e })
                    }}
                    disabled={roadmaps_loading}
                  />
                </div>
                <div style={{ minWidth: '11rem' }}>
                  <Select
                    placeholder="Filter category"
                    options={[
                      {value: null, label: 'All Categories'},
                      ...this.generateCategries()
                    ]}
                    value={filterCategory}
                    onChange={(e) => {
                      this.props.roadmapSearch({
                        search: this.state.search,
                        category: e ? e.value : null,
                        status: this.state.filterStatus?.value ? this.state.filterStatus?.value : null,
                      })
                      this.setState({ filterCategory: e })
                    }}
                    disabled={roadmaps_loading}
                  />
                </div>
                </React.Fragment>
                }
                <InputGroup className="input-group-search">
                  <InputGroupText>
                    <span className="icon icon-search" />
                  </InputGroupText>
                  <Input
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => this.setState({search: e.target.value})}
                    onFocus={() => this.setState({ searchFocus: true })}
                    onBlur={() => this.setState({ searchFocus: false })}
                    disabled={roadmaps_loading}
                  />
                  {search &&
                  <Button
                    className="btn-clear"
                    role="button"
                    onClick={() => {
                      // reset search
                      this.setState({search: ""});
                    }}
                  >
                    &#x2715;
                  </Button>
                  }
                  <Button
                    color="light"
                    disabled={roadmaps_loading}
                    onClick={() => this.props.roadmapSearch({
                      search: this.state.search,
                      category: this.state.filterCategory?.value ? this.state.filterCategory?.value : null,
                      status: this.state.filterStatus?.value ? this.state.filterStatus?.value : null,
                    })}
                  >
                    Go
                  </Button>
                </InputGroup>
              </CardBody>
              {roadmaps_loading ?
              <div className="feature-request-list">
                {Array.apply(null, Array(10)).map((item, idx) => (
                  <div className="card-body border-bottom" key={`request-item-${idx}`}>
                    <Placeholder animation="glow" tag="div">
                    <div className="d-flex">
                      <Placeholder tag="div" style={{ width: '1.8rem', height: '1.8rem' }} />
                      <div className="w-100 ps-2 d-flex flex-column gap-1">
                        <Placeholder md={6} tag="div" />
                        <Placeholder md={12} tag="div" size="xs" />
                        <Placeholder md={12} tag="div" size="xs" />
                        <Placeholder md={1} tag="div" size="xs" />
                      </div>
                    </div>
                    </Placeholder>
                  </div>
                ))}
              </div>
              :
              <React.Fragment>
              <div className="feature-request-list">
                {roadmaps?.items?.map(item => (
                <Link to={`/community/feature-roadmap/requests/${item.slug}`} className="card-body feature-request-item" key={`request-item-${item.id}`}>
                  <div className="feature-request-vote">
                    <span className="icon icon-caret-up" />
                    <span>{item.upvotes ? item.upvotes : 0}</span>
                  </div>
                  <div>
                    <h3 className="feature-request-title">{item.title}</h3>
                    <div className="feature-request-description">
                      <div className="truncate">
                        {item.description}
                      </div>
                    </div>
                    <div className="feature-request-metainfo">
                      <span><span className="icon icon-chat-bubble" /> {item.replies_count ? item.replies_count : 0}</span>
                      {item.status !== 'FEATURE_REQUEST' &&
                      <Badge color={statusClassMap[item.status]}>
                        {item.status?.replace("_", " ").toUpperCase()}
                      </Badge>
                      }
                    </div>
                  </div>
                  {userData.is_admin &&
                  <span
                    className="icon icon-trash bg-danger ms-auto"
                    style={{ flexShrink: 0 }}
                    role="button"
                    onClick={ e => {
                      e.preventDefault()
                      this.setState({ deleteRequestId: item.id })
                    }}
                    disable={roadmap_working}
                  />}
                </Link>
                ))}
              </div>
              <Pagination size="sm" className="mt-3" listClassName="justify-content-center">
                <PaginationItem disabled={roadmaps.current_page <= 1 ? true : false}>
                  <PaginationLink
                    onClick={() =>
                      this.props.roadmapSearch({
                        search: this.state.search,
                        category: this.state.filterCategory?.value ? this.state.filterCategory?.value : null,
                        status: this.state.filterStatus?.value ? this.state.filterStatus?.value : null,
                        page: roadmaps.current_page - 1,
                      })
                    }
                    disabled={roadmaps.current_page >= roadmaps.pages}
                  >Newer</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={roadmaps.current_page >= roadmaps.pages ? true : false}>
                  <PaginationLink
                    onClick={() =>
                      this.props.roadmapSearch({
                        search: this.state.search,
                        category: this.state.filterCategory?.value ? this.state.filterCategory?.value : null,
                        status: this.state.filterStatus?.value ? this.state.filterStatus?.value : null,
                        page: roadmaps.current_page + 1,
                      })
                    }
                    disabled={roadmaps.pages < roadmaps.current_page}
                  >Older</PaginationLink>
                </PaginationItem>
              </Pagination>
              </React.Fragment>
              }
            </Card>
          </Col>
        </Row>
        {fileSizeError &&
        <div className="position-fixed bottom-0 start-50 p-3 translate-middle-x" style={{ zIndex: 11 }}>
          <Toast isOpen={fileSizeError}>
            <ToastBody className="text-danger">Please upload image with size less than {FILE_UPLOAD_LIMIT_NUM} MB</ToastBody>
          </Toast>
        </div>
        }
        <LoginModal
          isOpen={showLoginModal}
          toggle={() => this.setState({ showLoginModal: !showLoginModal})}
        />
        {deleteRequestId &&
        <Dialog
          isOpen={true}
          close={() => this.setState({ deleteRequestId: null })}
          type="delete"
          title="Delete request"
          message={<span>Feature request and all replies will be deleted.
          <br/>Are you sure want to delete this request?</span>}
          deleteButtonText="Delete request"
          onDelete={() => {
            this.props.adminRoadmapDelete({id: deleteRequestId})
            this.setState({ deleteRequestId: null })
          }}
        />
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    roadmap_categories: state.Community.get("roadmap_categories"),
    roadmap_working: state.Community.get("roadmap_working"),
    roadmaps: state.Community.get("roadmaps"),
    roadmaps_loading: state.Community.get("roadmaps_loading"),
    roadmap_image: state.Community.get("roadmap_image"),
    roadmap_image_loading: state.Community.get("roadmap_image_loading"),
  }),
  {
    roadmapGetCategories,
    roadmapCreate,
    roadmapSearch,
    roadmapUploadImage,
    roadmapClearImage,
    adminRoadmapDelete,
  }
)(FeatureRequest);
