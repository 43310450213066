import { takeLatest, put, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import { sdMessage } from "../utils";
import { openInNewTab } from "../utils";

export const default_config = {
  error_show: false,
  error: "",
  success_show: true,
  success: "",
  failed_show: true,
  failed: "",
  redirect_on_success: null,
  redirect_on_fail: null,
  open_in_tab: false,
}

export function* commonSaga(action, method, endpoint, config=default_config,
  additional_actions=[], after_success_actions=[]){
	yield takeLatest(action, function*(payload) {
		try {
			const res = yield call(method, endpoint, payload.data);
			if (res.data.error) {
        yield put({
          type: `${action}_FAILED`,
          data: res.data,
        });
        if(config.failed_show){
          if(config.failed) {
            sdMessage(config.failed)
          } else if(res.data.error){
            sdMessage(res.data.error)
          } else {
            sdMessage(`Error calling endpoint [${endpoint}]`)
          }
			  }
        if(config.redirect_on_fail){
          yield put(push(config.redirect_on_fail));
        }
			} else {
        yield put({
          type: `${action}_SUCCESS`,
          data: res.data,
        });
        yield after_success_actions.map(action => {
          return action.function(res, ...action.params);
        })
        if(config.success_show){
          if(config.success) {
            sdMessage(config.success, "success")
          } else if(res.data.message){
            sdMessage(res.data.message, "success")
          } else {
            sdMessage("Success", "success")
          }
        }
        if(config.open_in_tab && res?.data?.data?.url){
          openInNewTab(res.data.data.url);
        }
        if(payload?.data?.redirect_on_success){
          if(payload.data.redirect_on_success.toLowerCase().includes('http')){
            //external redirection
            window.location.href = payload.data.redirect_on_success;
          } else {
            yield put(push(payload.data.redirect_on_success));
          }
        } else if (res?.data?.data?.redirect_on_success) {
          if(res.data.data.redirect_on_success.toLowerCase().includes('http')){
            //external redirection
            window.location.href = res.data.data.redirect_on_success;
          } else {
            yield put(push(res.data.data.redirect_on_success));
          }
        } else {
          if(config.redirect_on_success){
            yield put(push(config.redirect_on_success));
          }
        }
      }
		} catch (error) {
        yield put({
          type: `${action}_FAILED`,
        });
        if(config.error_show){
          if(config.error) {
            sdMessage(config.error)
          } else {
            sdMessage(error)
          }
			  }
      console.log('commonSaga ERROR -', error);
		}
    yield additional_actions.map(action => {
      return put(action.function(...action.params));
    })
	})
}
