import React, { Component } from 'react'
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import { Link, NavLink as NavLinkRoute, useParams } from "react-router-dom"
import { Badge, Button, Col, Input, InputGroup, InputGroupText, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Row, TabContent, TabPane } from "reactstrap"
import Select from 'react-select'
import authActions from "../../redux/auth/actions";
import communityActions from "../../redux/community/actions";
import moment from "moment"
import LoginModal from "./LoginModal"
import PostModal from "./PostModal"
import LoginForm from "./LoginForm"

const { userLogout } = authActions;
const {
  communityGetCategories,
  communityGetAllQuestions,
} = communityActions;


const pageTitle = 'Topic Name';

// Pinned questions by admin always come first, regardless active sort option
const sortOptions = [
  {value: 'newest', label: 'Newest'}, // last added questions come first
  {value: 'activity', label: 'Activity'}, // last replied questions come first
  {value: 'popular', label: 'Popular'} // questions with most replies come first
]

//TODO: Make sidebar reusable component

const popularArticles = [
  {id: 1, title: 'Amazon Flat File Errors', url: 'https://accelerlist.helpjuice.com/143973-seller-central/797286-amazon-flat-file-errors'},
  {id: 2, title: 'Dymo: My Label is Printing Sideways', url: 'https://accelerlist.helpjuice.com/143943-misc-printing-topics/dymo:-my-label-is-printing-sideways'},
  {id: 3, title: 'Installing AccelerPrint', url: 'https://accelerlist.helpjuice.com/144600-accelerprint/installing-accelerprint'},
  {id: 4, title: 'Dymo Printer & Chrome Browser Troubleshooting', url: 'https://accelerlist.helpjuice.com/143946-dymo-printer-setup/dymo-printer-&-chrome-browser-troubleshooting'},
  {id: 5, title: 'Speed Listing Products', url: 'https://accelerlist.helpjuice.com/listing-products/speed-listing-products'},
  {id: 6, title: 'How to Setup Rollo Printer in Windows', url: 'https://accelerlist.helpjuice.com/143945-rollo-printer-setup/how-to-setup-rollo-printer-in-windows'}
]

const CategoryLinkItem = ({ id, slug, name }) => {
  const params = useParams()
  return (
    <li className="topics-nav-item" key={`cat-nav-topic-id-${id}`}>
      <NavLinkRoute
        to={`/community/questions/topic/${slug}`}
        active={params.slug === slug}
      >
        <span className="icon icon-category" />
        <span className="topics-nav-title">{name}</span>
      </NavLinkRoute>
    </li>
  )
}

class SingleTopic extends Component {
  state = {
    activeTab: '',
    showPostModal: false,
    activeSort: sortOptions[1],
    showLoginModal: false,
    isAdmin: true
  }

  componentDidMount = () => {
    this.props.communityGetCategories();
    this.props.communityGetAllQuestions();
  }

  askQuestion = () => {
    this.setState({ showPostModal: true })
  }

  displayCategories = () => {
    const { categories } = this.props;

    return (
      <React.Fragment>
        {Object.keys(categories).map(cat_key => {
          return (
            <div className="category-nav" key={`cat-nav-id-${cat_key}`}>
              <span className="category-nav-title">{categories[cat_key]?.name}</span>
              <ul className="topics-nav">
                {Object.keys(categories[cat_key]?.topics).map(top_key => {
                  // let's pretend that this is our slug
                  // since we control the topics (at admin area), we can manually create slug for each topic
                  // so in this case, there will be a slug input for topic when admin add/edit it
                  const slug = categories[cat_key]?.topics[top_key]?.name.toLowerCase()
                  return (
                    <CategoryLinkItem
                      id={top_key}
                      slug={slug}
                      name={categories[cat_key]?.topics[top_key]?.name}
                    />
                  )
                })}
              </ul>
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  render() {
    const { activeTab, showPostModal, showLoginModal } = this.state
    const { userData, categories, questions } = this.props;

    return (
      <Row className="single-topic">
        <Helmet>
          <title>{pageTitle} | AccelerList</title>
          <meta name="description" content={`${pageTitle} questions`} />
        </Helmet>
        <Col md={9} className="pe-4">
          <div className="inner-page-header">
            <div className="hstack justify-content-between">
              <Link to="/community/questions">&larr; Back to questions</Link>
              <Button color="primary" onClick={this.askQuestion}>Ask a question</Button>
            </div>
            <div className="hstack justify-content-between mt-3">
              <h1 className="inner-page-title">{`${pageTitle} questions`}</h1>
              <div>
                <Select
                  options={sortOptions}
                  value={this.state.activeSort}
                  onChange={ selected => this.setState({ activeSort: selected }) }
                />
              </div>
            </div>
            <PostModal
              isOpen={showPostModal}
              isLoggedIn={userData?.username ? true : false}
              toggle={() => this.setState({ showPostModal: !showPostModal})}
              onLogin={() => this.setState({ isLoggedIn: true })}
              categories={categories}
            />
            <LoginModal
              isOpen={showLoginModal}
              toggle={() => this.setState({ showLoginModal: !showLoginModal})}
            />
          </div>
          <div className="inner-page-content">
            <InputGroup>
              <InputGroupText>
                <span className="icon icon-search" />
              </InputGroupText>
              <Input placeholder="Search..." />
            </InputGroup>
            <ul className="questions-list">
              <li className="questions-list-header">
                <div className="questions-list-header-col"></div>
                <div className="questions-list-header-col">Question/Topic</div>
                <div className="questions-list-header-col">Replies</div>
                <div className="questions-list-header-col">Last active</div>
                {this.state.isAdmin &&
                <div className="questions-list-header-col"></div>
                }
              </li>
              {questions?.map(item => (
              <li className="questions-list-item" title={item.title} key={`question-${item.id}`}>
                <Link to={`/community/questions/${item.slug}`}>
                  <div className="questions-list-item-col text-center">
                    {item.is_resolved ? <span className="icon icon-14 icon-check-square bg-success" /> : null}
                  </div>
                  <div className="questions-list-item-col">
                    <h2 className="question-title text-truncate">{item.title}</h2>
                    <span className="question-category">{item.category?.name}</span>
                  </div>
                  <div className="questions-list-item-col">{item.replies_count ? item.replies_count : 0}</div>
                  <div className="questions-list-item-col">{moment(item.updated_at).from(new Date())}<br/>by {item.last_active_by}</div>
                  {/* Admin view only, to pin question */}
                  {this.state.isAdmin &&
                  <div className="questions-list-item-col text-end">
                    <span
                      // if pinned, append class "bg-danger" to className
                      className="icon icon-18 icon-pin"
                      title="Pin question"
                      role="button"
                      onClick={e => this.pin(e, item.id)}
                    />
                  </div>
                  }
                </Link>
              </li>
              ))}
            </ul>
            <Pagination size="sm" listClassName="justify-content-center">
              <PaginationItem disabled>
                <PaginationLink>Newer</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>Older</PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
        </Col>
        <Col md={3} className="community-col-left-separator">
          <div className="community-sidebar-item">
            <div className="community-sidebar-header">
              <h3 className="community-sidebar-title">My Profile</h3>
              {userData?.username &&
              <Button
                color="link"
                onClick={() => this.props.userLogout()}
              >Logout</Button>
              }
            </div>
            {userData?.username ?
            <React.Fragment>
              <div className="community-profile-info">
                <div className="community-profile-info-avatar" style={{ backgroundImage: `url(${"https://placehold.co/50"})`}}>
                  <img src="https://placehold.co/50" alt="" />
                </div>
                <div>
                  <h6 className="community-profile-info-name">{userData?.username}</h6>
                  <span className="text-muted">{userData?.email}</span>
                </div>
              </div>
              {/*<Button color="secondary" block className="mt-2">Edit profile</Button>*/}
            </React.Fragment>
            :
            <React.Fragment>
              <div>
                <p>Ask questions and get answers, please login or create account.</p>
                <p><Badge color="info">AccelerList user?</Badge> Login with your AccelerList credentials.</p>
              </div>
              {activeTab === '' ?
              <div className="d-flex flex-column gap-2">
                <Button color="primary" onClick={() => this.setState({ activeTab: 'login' })}>Login to community</Button>
                {/*<Button color="secondary" onClick={() => this.setState({ activeTab: 'signup' })}>Create account</Button>*/}
              </div>
              :
              <div>
                <Nav pills>
                  <NavItem>
                    <NavLink active={activeTab === 'login'} onClick={() => this.setState({ activeTab: 'login' })}>
                      Community Login
                    </NavLink>
                  </NavItem>
                  {/*
                  <NavItem>
                    <NavLink active={activeTab === 'signup'} onClick={() => this.setState({ activeTab: 'signup' })}>
                      Signup
                    </NavLink>
                  </NavItem>
                  */}
                </Nav>
                <TabContent activeTab={activeTab} className="pt-3">
                  <TabPane tabId="login">
                    <LoginForm />
                  </TabPane>
                  {/*
                  <TabPane tabId="signup">
                    <FormGroup>
                      <Label>First Name</Label>
                      <Input placeholder="Your first name" />
                    </FormGroup>
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input placeholder="Your last name" />
                    </FormGroup>
                    <FormGroup>
                      <Label>Email address</Label>
                      <Input type="email" placeholder="Your email address" />
                    </FormGroup>
                    <FormGroup>
                      <Label>Password</Label>
                      <Input placeholder="Password" />
                    </FormGroup>
                    <Button color="primary" block>Sign up</Button>
                  </TabPane>
                  */}
                </TabContent>
              </div>
              }
            </React.Fragment>
            }
          </div>
          <div className="community-sidebar-item">
            <div className="community-sidebar-header">
              <h3 className="community-sidebar-title">Topics</h3>
            </div>
            {this.displayCategories()}
          </div>
          <div className="community-sidebar-item">
            <div className="community-sidebar-header">
              <h3 className="community-sidebar-title">Popular Help Articles</h3>
            </div>
            <ul className="list-unstyled">
              {popularArticles.map(item => (
                <li key={`article-${item.id}`} className="mb-3">
                  <a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    login_working: state.Auth.get("login_working"),
    categories: state.Community.get("categories"),
    categories_working: state.Community.get("categories_working"),
    questions: state.Community.get("questions"),
    questions_working: state.Community.get("questions_working"),
  }),
  {
    userLogout,
    communityGetCategories,
    communityGetAllQuestions,
  }
)(SingleTopic);
