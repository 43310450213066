import React, { Component} from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { openInNewTab } from "../../helpers/utils";
import { windowsIcon, linuxIcon, macIcon } from "../../assets/images";
import './style.css';

class AccelerPrint extends Component {
  render() {
    return (
      <div className="inner-page page-accelerprint">
        <div className="inner-page-header">
          <Container>
            <h1 className="inner-page-title">Download AccelerPrint</h1>
            <p className="inner-page-subtitle">Download and use our free custom print solution, AccelerPrint, you will enjoy using the thermal printer of your choice in the browser of your choice.</p>
          </Container>
        </div>
        <div className="inner-page-content">
          <Container>
            <h2 className="section-title">Choose Platform</h2>
            <Row>
              <Col>
                <div className="download-item">
                  <img src={macIcon} alt="Mac" />
                  <Button
                    className="btn-download"
                    onClick={() => openInNewTab("https://rennbrookventuresllc.box.com/s/2xa08qcyd81ibmx2y6qucz3o2t841hin")}
                  >Download for Mac</Button>
                  <span className="text-muted">60.4 MB</span>
                </div>
              </Col>
              <Col>
                <div className="download-item">
                  <img src={windowsIcon} alt="Windows" />
                  <Button
                    className="btn-download"
                    onClick={() => openInNewTab("https://rennbrookventuresllc.box.com/s/t077tslm2odqikkpih5p7ckmobb1ej4r")}
                  >Download for Windows</Button>
                  <span className="text-muted">56.6 MB</span>
                </div>
              </Col>
              <Col>
                <div className="download-item">
                  <img src={linuxIcon} alt="Linux" />
                    <Button
                      className="btn-download"
                      onClick={() => openInNewTab("https://rennbrookventuresllc.box.com/s/b74444bsn2pgbt9iunf568is697smm9z")}
                    >Download for Linux</Button>
                  <span className="text-muted">62.5 MB</span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}

export default AccelerPrint;