import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import './style.css';

const iconMap = {
  "success": "icon-check",
  "error": "icon-warning",
  "info": "icon-info",
  "delete": "icon-trash"
}

class Dialog extends Component {
  render() {
    const {
      isOpen,
      close,
      type,
      title,
      message,
      deleteButtonText,
      onDelete
    } = this.props;

    return (
      <Modal
        modalClassName={`dialog dialog-${type}`}
        isOpen={isOpen}
        centered
      >
        <ModalBody>
          <div className="dialog-icon">
            <span className={`icon ${iconMap[type]}`}></span>
          </div>
          <div className="dialog-text">
            <h6 className="dialog-title">{title}</h6>
            <p className="dialog-message">{message}</p>
          </div>
        </ModalBody>
        <ModalFooter>
          {type === 'delete' ?
          <div className="hstack gap-2 w-100">
            <Button onClick={close} block>Cancel</Button>
            <Button color="danger" onClick={onDelete} block>{deleteButtonText}</Button>
          </div>
          :
          <Button onClick={close} block>Close</Button>
          }
        </ModalFooter>
      </Modal>
    )
  }
}

export default Dialog;