import React, { Component } from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { openInNewTab } from "../../../helpers/utils";
import { laptopGreen } from "../../../assets/images";

const desktop_download = {
  "mac_url": "https://rennbrookventuresllc.app.box.com/s/26ni7ebw0oc4fwgwdlrxjidt5211le6p",
  "version": "1.0.0",
  "win_url": "https://rennbrookventuresllc.app.box.com/s/4pbni8v47ftzr305y44av83h4qau5wub/file/762015809847"
}

class Desktop extends Component {
  render() {
    return (
      <section className="desktop-download">
        <Container>
          <Row>
            <Col lg={5} className="mb-3 mb-lg-0 text-center text-lg-start">
              <img src={laptopGreen} alt="" height={220} />
            </Col>
            <Col lg={7}>
              <div className="section-heading text-center text-lg-start">
                <h2 className="section-title">AccelerList for Desktop</h2>
                <p className="section-subtitle">Download our desktop app and enjoy direct thermal printing communication and automatic updates.</p>
                { desktop_download && desktop_download.version
                  ? <p className="version mb-0">Version {desktop_download.version}</p>
                  : null
                }
              </div>
              <div className="flex-column flex-lg-row d-flex gap-3 justify-content-center justify-content-lg-start">
              { desktop_download && desktop_download.mac_url ?
                <Button
                  color="info"
                  className="hstack justify-content-center gap-1"
                  onClick={e => openInNewTab(desktop_download.mac_url)}
                >
                  <span className="icon icon-apple icon-18 bg-white"></span>
                  <span>Download (Mac)</span>
                </Button>
                : null
              }
              { desktop_download && desktop_download.win_url ?
                <Button
                  color="info"
                  className="hstack justify-content-center gap-1"
                  onClick={e => openInNewTab(desktop_download.win_url)}
                >
                  <span className="icon icon-windows icon-18 bg-white"></span> Download (Windows)
                </Button>
                : null
              }
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}
export default Desktop;