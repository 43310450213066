import React, { Component } from 'react';
import Hero from "./Hero";
import Statistic from "./Statistic";
import Feature from "./Features";
import StatisticBig from "./StatisticBig";
import ProfitHunt from "./ProfitHunt";
import Testimonials from "./Testimonials";
import Pricing from "./Pricing";
import Blog from "./Blog";
import Desktop from "./Desktop";
import ProfitProof from "./ProfitProof";
import GarageMoney from "./GarageMoney";
import CardsBanner from "./CardsBanner";
import Ebay from "./Ebay";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Hero />
        <Statistic />
        <ProfitProof />
        <Feature />
        <Ebay />
        <StatisticBig />
        <GarageMoney />
        <CardsBanner />
        <ProfitHunt />
        <Testimonials />
        <Pricing />
        <Blog />
        <Desktop />
      </React.Fragment>
    )
  }
}

export default Home;
