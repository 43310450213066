import React, { Component } from 'react';
import { Col, Container, Row } from "reactstrap";
import VideoPlayer from "../../shared/components/VideoPlayer";

const StarRating = ({id, rating}) => {
  if(rating > 0) {
    return (
      <div className="star-rating mb-1">
      {Array.from(Array(rating)).map((item, idx) => (
        <span className="icon icon-star" key={`rating-${id}-${idx}`}></span>
      ))}
      </div>
    )
  }
  return null
}

class Testimonials extends Component {
  render() {
    const { data } = this.props

    return (
    <Container fluid>
      <section className="testimonial-section">
        <Row>
          {data.map((item, idx) => {
            if(item.type === 'text') {
              return (
              <Col lg={4} key={`testimonials-${idx}`} className="testimonial-col">
                <div className="testimonial">
                  <div className="testimonial-top">
                    <div className="testimonial-rating">
                      <StarRating
                        id={`rating-${idx}`}
                        rating={5}
                      />
                    </div>
                    <div className="testimonial-text">
                      {item.content}
                    </div>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-avatar">{item.name.slice(0,1).toUpperCase()}</div>
                    <p className="testimonial-name">{item.name}</p>
                  </div>
                </div>
              </Col>
              )
            } else {
              return (
                <Col lg={4} key={`testimonials-${idx}`} className="testimonial-col">
                  <VideoPlayer
                    id={item.video_id}
                    title={item.name}
                  />
                </Col>
              )
            }
          })}
        </Row>
      </section>
    </Container>
    )
  }
}

export default Testimonials;