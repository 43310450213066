import React, { Component } from 'react';
import { printBrother, printerDymo, printerRollo, printerZebra } from "../../assets/images";
import { Button, Col, Container, Row } from "reactstrap";
import { openInNewTab } from "../../helpers/utils";

const download_urls = {
  dymo: {
    win: "https://download.dymo.com/dymo/Software/DYMO%20Connect%201.4.2/DCDSetup1.4.2.82.exe",
    mac: "https://download.dymo.com/dymo/Software/DYMO%20Connect%201.4.2/DCDMac1.4.2.69.pkg",
    linux: "",
  },
  zebra: {
    win: "https://www.zebra.com/us/en/support-downloads/printer-software/printer-setup-utilities.html",
    mac: "",
    linux: "",
  },
  rollo: {
    win: "https://rollo.com/driver-dl/Rollo-Windows-Latest.zip",
    mac: "https://www.rolloprinter.com/driver-dl/Rollo-Mac-Latest.dmg",
    linux: "https://www.rollo.com/driver-dl/beta/rollo-driver-ubuntu_x86_64_v1.0.2.tar.gz ",
  },
  brother: {
    win: "https://support.brother.com/g/b/productseries.aspx?c=us&lang=en&content=dl&pcatid=37",
    mac: "https://support.brother.com/g/b/productseries.aspx?c=us&lang=en&content=dl&pcatid=37",
    linux: "https://support.brother.com/g/b/productseries.aspx?c=us&lang=en&content=dl&pcatid=37",
  }
}

class PrintingDrivers extends Component {
  render() {
    return (
      <div className="inner-page alternatives">
        <div className="inner-page-header">
          <Container>
            <h1 className="inner-page-title">Download Printer Drivers</h1>
            <p className="inner-page-subtitle">Download driver for thermal printer with brand such as Dymo, Zebra, Rollo and Brother.</p>
          </Container>
        </div>
        <div className="inner-page-content">
          <Container>
            <h2 className="section-title">Choose Driver</h2>
            <Row>
              <Col>
                <div className="download-item mb-5 mb-lg-0 text-center">
                  <img src={printerDymo} height="80" alt="Dymo" />
                  <h5 className="mt-3 h6">Download Dymo Driver</h5>
                  <div className="hstack gap-2 justify-content-center">
                    <Button
                      color="link"
                      className="text-success"
                      title="Download driver for Mac"
                      onClick={() => openInNewTab(download_urls.dymo.mac)}
                    ><span className="icon icon-apple icon-22 bg-info"></span></Button>
                    <Button
                      color="link"
                      className="text-success"
                      title="Download driver for Windows"
                      onClick={() => openInNewTab(download_urls.dymo.win)}
                    ><span className="icon icon-windows icon-22 bg-info"></span></Button>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="download-item mb-5 mb-lg-0 text-center">
                  <img src={printerZebra} height="80" alt="Zebra" />
                  <h5 className="mt-3 h6">Download Zebra Driver</h5>
                  <div className="hstack gap-2 justify-content-center">
                    <Button
                      color="link"
                      className="text-success"
                      title="Download driver for Windows"
                      onClick={() => openInNewTab(download_urls.zebra.win)}
                    ><span className="icon icon-windows icon-22 bg-info"></span></Button>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="download-item mb-5 mb-lg-0 text-center">
                  <img src={printerRollo} height="80" alt="Rollo" />
                  <h5 className="mt-3 h6">Download Rollo Driver</h5>
                  <div className="hstack gap-2 justify-content-center">
                    <Button
                      color="link"
                      className="text-success"
                      title="Download driver for Mac"
                      onClick={() => openInNewTab(download_urls.rollo.mac)}
                    ><span className="icon icon-apple icon-22 bg-info"></span></Button>
                    <Button
                      color="link"
                      className="text-success"
                      title="Download driver for Windows"
                      onClick={() => openInNewTab(download_urls.rollo.win)}
                    ><span className="icon icon-windows icon-22 bg-info"></span></Button>
                    <Button
                      color="link"
                      className="text-success"
                      title="Download driver for Linux"
                      onClick={() => openInNewTab(download_urls.rollo.linux)}
                    ><span className="icon icon-linux icon-22 bg-info"></span></Button>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="download-item mb-5 mb-lg-0 text-center">
                  <img src={printBrother} height="80" alt="Brother" />
                  <h5 className="mt-3 h6">Download Brother Driver</h5>
                  <div className="hstack gap-2 justify-content-center">
                    <Button
                      color="link"
                      className="text-success"
                      title="Download driver for Mac"
                      onClick={() => openInNewTab(download_urls.brother.mac)}
                    ><span className="icon icon-apple icon-22 bg-info"></span></Button>
                    <Button
                      color="link"
                      className="text-success"
                      title="Download driver for Windows"
                      onClick={() => openInNewTab(download_urls.brother.win)}
                    ><span className="icon icon-windows icon-22 bg-info"></span></Button>
                    <Button
                      color="link"
                      className="text-success"
                      title="Download driver for Linux"
                      onClick={() => openInNewTab(download_urls.brother.linux)}
                    ><span className="icon icon-linux icon-22 bg-info"></span></Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}

export default PrintingDrivers;