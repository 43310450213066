import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopNav from "./shared/components/TopNav";
import Footer from "./shared/components/Footer";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import { appConfig } from './config/app';
import authActions from './redux/auth/actions';
import userActions from './redux/user/actions';

import Home from "./views/Home";
import Alternatives from "./views/Alternatives";
import TOS from "./views/TOS";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Press from "./views/Press";
import AccelerPrint from "./views/AccelerPrint";
import PrintingDrivers from "./views/PrintingDrivers";
import FaqEbay from "./views/FaqEbay";
import AboutUs from "./views/AboutUs";
import Reviews from "./views/Reviews";
import Community from "./views/Community";
import CommunityQuestions from "./views/Community/CommunityQuestions";
import QuestionDetails from "./views/Community/QuestionDetails";
import CommunityProductUpdates from "./views/Community/CommunityProductUpdates";
import CommunityRoadmap from "./views/Community/CommunityRoadmap";
import SingleTopic from "./views/Community/SingleTopic";
import { Spinner } from "reactstrap";
import Profile from "./views/Community/Profile";
import CommunityAdmin from "./views/Community/CommunityAdmin";
import Page404 from "./views/Page404";
import RequestDetails from "./views/Community/FeatureRequest/RequestDetails";
import Affiliates from "./views/Affiliates";

const { userRefreshToken, } = authActions;
const { userGetData } = userActions;

class PageTemplate extends Component {

  componentDidMount(){
    setInterval(
      () => {
        if(this.props.userData?.username){
          this.props.userRefreshToken();
        }
      },
      appConfig.auth.refreshTokenFrequency
    );

    this.props.userGetData();
  }

  render() {
    return (
      this.props.user_data_loaded
      ?
        <div className="landing">
          <div className="site-header">
            <TopNav />
          </div>
          <div className="site-content">
            <Outlet />
            <Footer />
          </div>
        </div>
      : <div className="landing fullscreen-loading">
          <Spinner color="primary" />
          <div>Loading...</div>
        </div>
    )
  }
}

PageTemplate = connect(
  state => ({
    userData: state.User.get("userData"),
    user_data_loaded: state.User.get("user_data_loaded"),
  }),
  {
    userRefreshToken,
    userGetData,
  }
)(PageTemplate);

class AppRoutes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PageTemplate />}>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/accelerlist_alternatives" element={<Alternatives />} />
            <Route path="/terms_conditions" element={<TOS />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/press" element={<Press />} />
            <Route path="/accelerprint" element={<AccelerPrint />} />
            <Route path="/printing_drivers" element={<PrintingDrivers />} />
            <Route path="/faq_ebay" element={<FaqEbay />} />
            <Route path="/about_us" element={<AboutUs />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/community/" element={<Community />}>
              <Route path="/community/questions" element={<CommunityQuestions />} />
              <Route path="/community/questions/:category/:slug" element={<CommunityQuestions />} />
              <Route path="/community/questions/:slug" element={<QuestionDetails />} />
              <Route path="/community/product-updates" element={<CommunityProductUpdates />} />
              <Route path="/community/feature-roadmap" element={<CommunityRoadmap />} />
              <Route path="/community/questions/topic/:slug" element={<SingleTopic />} />
              <Route path="/community/profile" element={<Profile />} />
              <Route path="/community/admin" element={<CommunityAdmin />} />
              <Route path="/community/feature-roadmap/requests/:slug" element={<RequestDetails />} />
            </Route>
            <Route path="/affiliates" element={<Affiliates />} />
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    )
  }
}

function Signin() {
  window.location.href = 'https://app.accelerlist.com/signin';
  return null;
}

export default connect(
  state => ({
    isLoggedIn: state.Auth.get("access_token") !== null,
  }),
  {
    userRefreshToken,
  }
)(AppRoutes);
