import React, { Component } from 'react';
import { Container } from "reactstrap";
import Slider from 'react-slick';
import { catBooks, catDvd, catTools, flagCA, flagUK, flagUS, proofRob, proofKarin, proofJohn } from "../../../assets/images";
import './style.css';

const data = [
  {img_url: proofRob ,name: 'Rob F', place: 'California', profit: '$45,529.00', since: '2019', marketplace: 'US', category: 'books',
  quote: 'One of the first pieces of advice offered from most sellers was to invest in a listing software. Accelerlist was always the top recommended software.'},
  {img_url: proofKarin, name: 'Karin W', place: 'Iowa', profit: '$19,479.00', since: '2018', marketplace: 'US', category: 'books',
  quote: 'Before using AccelerList to list my products it took me 10 to 20 times longer to list things online. Accelerlist is a game changer and will help your Amazon business as well.'},
  {img_url: proofJohn, name: 'John M', place: 'Pennsylvania', profit: '$20,000.00', since: '2019', marketplace: 'US', category: 'books',
  quote: 'Listing software is a must if you\'re selling on Amazon. AccelerList is a great solution to get your books listed quickly. You\'ll be amazed how much time you\'ll save.'},
]

const marketplaceMap = {
  "US": <span className="proof-item-details-icon"><img src={flagUS} alt="US" /><span>US Marketplace</span></span>,
  "UK": <span className="proof-item-details-icon"><img src={flagUK} alt="UK" /><span>UK Marketplace</span></span>,
  "CA": <span className="proof-item-details-icon"><img src={flagCA} alt="CA" /><span>CA Marketplace</span></span>
}

const categoryMap = {
  "books": <span className="proof-item-details-icon"><img src={catBooks} alt="Books" /><span>Books Category</span></span>,
  "dvd": <span className="proof-item-details-icon"><img src={catDvd} alt="DVDs" /><span>DVDs Category</span></span>,
  "tools": <span className="proof-item-details-icon"><img src={catTools} alt="Tools" /><span>Tools Category</span></span>
}

class ProfitProof extends Component {
  render() {
    return (
      <section className="profit-proof">
        <Container>
          <div className="section-heading">
            <h2 className="section-title">Real profits, real people. A side hustle with no limits!</h2>
          </div>
          <Slider
            dots={true}
            infinite={true}
            slidesToScroll={3}
            slidesToShow={3}
            //autoplay
            responsive={[
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: false
                }
              }
            ]}
          >
            {data.map((item, idx) => (
            <div className="proof-item" key={`proof-${idx}`}>
              <div className="proof-item-inner">
                <div className="proof-item-top">
                  <div className="proof-item-user">
                    <div className="proof-item-user-image" style={{ backgroundImage: `url(${item.img_url})` }}>
                      <img src={item.img_url} alt={item.name} />
                    </div>
                    <h3 className="proof-item-user-name">{item.name}</h3>
                    <div className="proof-item-user-place">{item.place}</div>
                  </div>
                </div>
                <div className="proof-item-bottom">
                  <div className="proof-item-bottom-inner">
                    <p className="proof-item-profit">{item.profit}</p>
                    <div className="proof-item-details">
                      <p>{marketplaceMap[item.marketplace]}</p>
                      <p>{categoryMap[item.category]}</p>
                    </div>
                    <div className="proof-item-quote">
                      "{item.quote}"
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            ))}
          </Slider>
        </Container>
      </section>
    )
  }
}

export default ProfitProof;