const authActions = {
	USER_LOGIN: 'USER_LOGIN',
	USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
	USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',

	USER_LOGOUT: 'USER_LOGOUT',

	USER_REFRESH_TOKEN: 'USER_REFRESH_TOKEN',
	USER_REFRESH_TOKEN_SUCCESS: 'USER_REFRESH_TOKEN_SUCCESS',
	USER_REFRESH_TOKEN_ERROR: 'USER_REFRESH_TOKEN_ERROR',

	USER_REFRESH_TOKEN_LOCAL: 'USER_REFRESH_TOKEN_LOCAL',
	USER_REGISTER: 'USER_REGISTER',

	userRegister: (data) => ({
		type: authActions.USER_REGISTER,
    data,
	}),

	userRefreshTokenLocal: () => ({
		type: authActions.USER_REFRESH_TOKEN_LOCAL,
	}),

	userRefreshToken: () => ({
		type: authActions.USER_REFRESH_TOKEN,
	}),
	userRefreshTokenSuccess: data => ({
		type: authActions.USER_REFRESH_TOKEN_SUCCESS,
		data
	}),
	userRefreshTokenError: () => ({
		type: authActions.USER_REFRESH_TOKEN_ERROR,
	}),

	userLogout: (data) => ({
		type: authActions.USER_LOGOUT,
		data
	}),

	userLogin: data => ({
		type: authActions.USER_LOGIN,
		data
	}),
	userLoginSuccess: data => ({
		type: authActions.USER_LOGIN_SUCCESS,
		data
	}),
	userLoginError: data => ({
		type: authActions.USER_LOGIN_ERROR,
		data
	}),
}

export default authActions;

