import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Button, FormGroup,
  //FormText,
  Input, Label, Modal, ModalBody, ModalFooter,
  ModalHeader, Toast, ToastBody, UncontrolledTooltip
  //Nav, NavItem, NavLink, TabContent, TabPane,
} from "reactstrap";
import userActions from "../../redux/user/actions";
import { Link } from "react-router-dom"
//import ProfileDiscussions from "./ProfileDiscussions";
import { usernameToColor } from '../../helpers/utils';

const {
  userSaveData,
} = userActions;

const FILE_UPLOAD_LIMIT_NUM = 1
const FILE_UPLOAD_LIMIT = FILE_UPLOAD_LIMIT_NUM * 1048576

class Profile extends Component {
  state = {
    activeTab: "1",
    showProfileModal: false,
    first_name: null,
    last_name: null,
    fileSizeError: false
  }

  onAvatarUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const uploadedFiles = e.target.files;

    let timer = null
    if(uploadedFiles[0]) {
      if(uploadedFiles[0].size > FILE_UPLOAD_LIMIT) {
        this.setState({ fileSizeError: true })
        timer = setTimeout(() => {
          this.setState({ fileSizeError: false })
          clearTimeout(timer)
        }, 4000)
      } else {
        var formData = new FormData();
        formData.append(
          "file",
          uploadedFiles[0]
        );
        this.props.userSaveData(formData);
      }
    }
  }

  render() {
    const { showProfileModal, first_name, last_name, fileSizeError } = this.state
    const { userData, updating_user_data } = this.props;

    return (
      <div className="profile">
        <div className="inner-page-header">
          <Link to="/community/questions">&larr; Back to questions</Link>
        </div>
        <div className="inner-page-header d-flex gap-4">
          <div
            className="avatar-wrapper"
          >
            <div
              id="avatar"
              className={`avatar ${userData?.username ? 'can-edit' : ''}`}
              style={userData?.profile_image && { backgroundImage: `url(${userData?.profile_image})`}}
              onClick={() => {
                if(userData?.username) this.upload.click()
                else return
              }}
            >
              {userData?.profile_image ?
              <img src={userData?.profile_image} alt={userData?.profile_image} />
              :
              <span
                className={`avatar-default`}
                style={usernameToColor(userData?.username)}
                title={userData?.username}
              >{userData?.username.charAt(0)}</span>
              }
            </div>
            {userData?.id === userData.id &&
            <React.Fragment>
              <UncontrolledTooltip target="avatar">Click to upload avatar</UncontrolledTooltip>
              {userData?.profile_image &&
              <React.Fragment>
                <UncontrolledTooltip target="removeAvatar">Remove avatar</UncontrolledTooltip>
                <span
                  id="removeAvatar"
                  className="remove-avatar"
                  role="button"
                  onClick={() => this.props.userSaveData({profile_image: null})}
                >&#x2715;</span>
              </React.Fragment>
              }
            </React.Fragment>
            }
            <input
              type="file"
              id="avatarFile"
              className="d-none"
              ref={(ref) => this.upload = ref}
              accept={"image/png, image/jpeg"}
              onChange={this.onAvatarUpload.bind(this)}
            />
          </div>
          <div>
            <h1 className="inner-page-title mb-1">{userData?.username}</h1>
            {userData?.first_name || userData?.last_name ?
            <p>{userData?.first_name} {userData?.last_name}</p>
            : null}
            {userData?.bio &&
            <div className="my-4">
              <h2 className="h6">Biography</h2>
              <div>
                When I was a kid the first thing I remember wanting to be was a pilot, so naturally here I am knee deep in code and data!<br/>
                Growing up was slightly different in Florida. Things that are normal there are growing up in the water and spending almost all
                of your free time in it. In the Gulf of Mexico for me. We’d go swimming, scuba diving, or fishing in that warm body of water
                almost every weekend.
              </div>
            </div>
            }
            {userData?.id === userData.id &&
            <Button color="primary" onClick={() => this.setState({ showProfileModal: true })}>Update profile</Button>
            }
          </div>
        </div>
          {/*
        <div className="inner-page-content">
          <Nav className="community-nav">
            <NavItem>
              <NavLink
                active={activeTab === "1"}
                role="button"
                onClick={() => this.setState({ activeTab: "1" })}
              >Discussions</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === "2"}
                role="button"
                onClick={() => this.setState({ activeTab: "2" })}
              >Upvoted Questions</NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {activeTab === "1" &&
              <ProfileDiscussions
                userData={userData}
              />
              }
            </TabPane>
            <TabPane tabId="2">
              UI is WIP: list all upvoted question by this user
            </TabPane>
          </TabContent>
        </div>
          */}
        <Modal isOpen={showProfileModal} toggle={() => this.setState({ showProfileModal: !showProfileModal })}>
          <ModalHeader toggle={() => this.setState({ showProfileModal: !showProfileModal })}>Update Profile</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>First Name</Label>
              <Input
                placeholder="Your first name"
                value={first_name === null ? userData?.first_name : first_name}
                onChange={(e) => this.setState({first_name: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label>Last Name</Label>
              <Input
                placeholder="Your last name"
                value={last_name === null ? userData?.last_name : last_name}
                onChange={(e) => this.setState({last_name: e.target.value})}
              />
            </FormGroup>
            {/*
            <FormGroup>
              <Label>Nickname</Label>
              <Input
                placeholder="Provide your nickname"
                value={last_name === null ? userData?.last_name : last_name}
                onChange={(e) => this.setState({last_name: e.target.value})}
              />
              <FormText>Nickname is visible to public</FormText>
            </FormGroup>
            <FormGroup>
              <Label>Bio</Label>
              <Input
                type="textarea"
                placeholder="Tell the community who you are"
                rows={8}
              />
            </FormGroup>
            */}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={updating_user_data}
              onClick={() => {
                this.props.userSaveData({
                  first_name: first_name,
                  last_name: last_name,
                });
                this.setState({
                  last_name: null,
                  first_name: null,
                })
                this.setState({ showProfileModal: false });
              }}
            >Save changes</Button>
          </ModalFooter>
        </Modal>
        {fileSizeError &&
        <div class="position-fixed bottom-0 start-50 p-3 translate-middle-x" style={{ zIndex: 11 }}>
          <Toast isOpen={fileSizeError}>
            <ToastBody className="text-danger">Please upload image with size less than {FILE_UPLOAD_LIMIT_NUM} MB</ToastBody>
          </Toast>
        </div>
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    updating_user_data: state.User.get("updating_user_data"),
  }),
  {
    userSaveData,
  }
)(Profile);
