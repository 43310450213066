import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, Spinner } from "reactstrap";
import Slider from 'react-slick';
import './style.css';
import VideoPlayer from "../../../shared/components/VideoPlayer";

const DEFAULT_GROUP_COUNT = 4
const videosRow = [
  { id: 'NOsMrV014B7UnIGF601Xl5bXIgDoGUS2558Cpk8MCdjjM', title: "Anna K" },
  { id: '1FBpYXW3oa00cgCXCK00zXdz6cXv4f8zx6rbhfT6IT5FM', title: "Jordan S" },
  { id: 'g8y1jXuiVKe2nAeMnG8EzjzfM2WzTRK00v400s5ZOS9m00', title: "Robert K" },
]

class Testimonials extends Component {
  state = {
    groupCount: DEFAULT_GROUP_COUNT
  }

  componentDidMount = () => {
    this.updateGroupCount()
    window.addEventListener('resize', this.updateGroupCount)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateGroupCount)
  }

  updateGroupCount = () => {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    let groupCount = DEFAULT_GROUP_COUNT
    
    if(screenWidth < 992) {
      groupCount = 2
    }
    if (screenWidth < 670) {
      groupCount = 1
    }
    this.setState({ groupCount })
  }

  render() {
    const groupTestimonials = []
    const textTestimonials = this.props.testimonials?.filter(item => item.type === 'text');

    if(textTestimonials) {
      // group testimonial by groupCount items for best visual slider purposes
      let group = []
      for (let i = 0; i < textTestimonials.length; i++) {
        if(i > 0 && i % this.state.groupCount === 0) {
          groupTestimonials.push(group)
          group = []
        }
        group.push(textTestimonials[i])
      }
      // push the leftovers
      groupTestimonials.push(group)
    }

    return (
      <section className="testimonial-section">
        <Container>
          <div className="section-heading">
            <h2 className="section-title">Amazon and eBay sellers love our support...</h2>
          </div>
          {this.props.testimonials_loading ?
          <div className="hstack justify-content-center gap-2"><Spinner size="sm" /> Loading testimonials...</div>
          :
          <Slider
            accessibility={false}
            touchMove={false}
            swipe={false}
            arrows={true}
            dots={false}
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={true}
            adaptiveHeight={true}
          >
            {groupTestimonials?.map((group, idxGroup) => (
              <div className="testimonial-group" key={`group-${idxGroup}`} tabIndex={-1}>
                {group?.map(item => (
                <div className="testimonial" key={`testimonial-${item.id}`}>
                  <div className="testimonial-top">
                    <div className="testimonial-rating">
                      <StarRating
                        id={item.id}
                        rating={item?.testimonial?.rating}
                      />
                    </div>
                    <div className="testimonial-text">
                      {item?.testimonial?.text}
                    </div>
                  </div>
                  <div className="testimonial-author">
                    <div
                      className="testimonial-avatar" 
                      style={item?.testimonial?.sent_by.avatar ? { backgroundImage: `url(${item?.testimonial?.sent_by.avatar})`} : null}
                    >
                      {item?.testimonial?.sent_by.avatar ?
                      <img src={item?.testimonial?.sent_by.avatar} alt="" />
                      : item?.testimonial?.sent_by?.name?.slice(0, 1)
                      }
                    </div>
                    <p className="testimonial-name">{item?.testimonial?.sent_by?.name}</p>
                  </div>
                </div>
                ))}
              </div>
            ))}
          </Slider>
          }

          <Row className="mt-5">
            {videosRow.map(item => (
              <Col md={4} key={item.id}>
                <VideoPlayer 
                  id={item.id}
                  title={item.title} 
                />
            </Col>
            ))}
          </Row>
        </Container>
      </section>
    )
  }
}

const StarRating = ({id, rating}) => {
  if(rating > 0) {
    return (
      <div className="star-rating mb-1">
      {Array.from(Array(rating)).map((item, idx) => (
        <span className="icon icon-star" key={`rating-${id}-${idx}`}></span>
      ))}
      </div>
    )
  }
  return null
}

export default connect(
  state => ({
    testimonials: state.Front.get("testimonials"),
    testimonials_loading: state.Front.get("testimonials_loading"),
  }),
  {
  }
)(Testimonials);

