import React, { Component } from 'react';
import { garageMoneyIllustration, garageMoneyLogo } from "../../../assets/images";
import { Col, Container, Input, InputGroup, Row } from "reactstrap";
import './style.css';

const articles = [
  {url: "https://www.accelerlist.com/blog/best-software-for-amazon-sellers/", title: "Best Software for Amazon Sellers: Boost Sales, Streamline Operations"},
  {url: "https://www.accelerlist.com/blog/alibaba-private-label/", title: "Alibaba Private Label: Read this before buying online"},
  {url: "https://www.accelerlist.com/blog/amazon-fba-private-label/", title: "Amazon FBA Private Label: A Comprehensive Guide"},
  {url: "https://accelerlist.com/blog/amazon-fba-calculator/", title: "Amazon FBA Calculator: Dummies' Guide to Profit 💰"},
  {url: "https://blog.accelerlist.com/inventory-lab/", title: "2019 Review: Inventory Lab vs AccelerList"},
  {url: "https://blog.accelerlist.com/amazon-seller-sku-5-tricks/", title: "5 tricks to master the Amazon Seller Sku or MSKU"},
  {url: "https://blog.accelerlist.com/let-amazon-label-products/", title: "Should you let Amazon label your products?"},
  {url: "https://blog.accelerlist.com/fba-sourcing-mistakes/", title: "Are you making these FBA sourcing mistakes?"},
  {url: "https://blog.accelerlist.com/selling-on-amazon-after-a-life-changing-accident/", title: "Selling on Amazon After a Life Changing Accident"},
]

const featuredArticles = [
  {
    id: 1, img_url: "https://i0.wp.com/blogaccelerlist.wpcomstaging.com/wp-content/uploads/2023/07/busy-warehouse-1.png?resize=640%2C427&ssl=1",
    title: "Selling Books on Amazon: The ALL IN guide to making money",
    description: "Selling books on Amazon is where most new Amazon sellers start. You can go straight to private label or wholesale selling but those approaches are riddled with money pits if you’re not careful.",
    url: "https://www.accelerlist.com/blog/selling-books-on-amazon/"
  },
  {
    id: 2, img_url: "https://i0.wp.com/blogaccelerlist.wpcomstaging.com/wp-content/uploads/2023/08/55.webp",
    title: "Amazon FBA Calculator: Use our FREE calculator to predict profits",
    description: "Are you an Amazon seller looking to calculate your profits and fees accurately? Our free Amazon FBA Calculator tool helps you determine the revenue, profit, and fees associated with using the Fulfillment by Amazon business model for any given product.",
    url: "https://www.accelerlist.com/blog//amazon-fba-calculator-free/"
  },
]

class GarageMoney extends Component {
  render() {
    return (
      <section className="garage-money">
        <Container>
          <Row>
            <Col xl={9}>
              <div className="garage-money-cta">
                <div className="garage-money-cta-left">
                  <img src={garageMoneyLogo} alt="Garage Money Logo" />
                  <h2 className="garage-money-cta-title">The Amazon & eBay Freedom Life™ Course</h2>
                  <p className="garage-money-cta-subtitle">The ultimate “get started” course for your online selling empire. Break free and start building the life you deserve!</p>
                  <div className="garage-money-subscribe">
                    <p>Subscribe below to get exclusive early access.</p>
                    <InputGroup>
                      <Input placeholder="Your email address" />
                      <button className="btn">SUBSCRIBE</button>
                    </InputGroup>
                  </div>
                </div>
                <div className="garage-money-cta-right">
                  <img src={garageMoneyIllustration} alt="" />
                </div>
              </div>
              <div className="garage-money-featured blog">
                {featuredArticles?.map(item => (
                <div className="blog-item" key={`post-${item.id}`}>
                  <a href={item.url} target="_blank" rel="noopender noreferrer">
                    <div className="blog-item-thumbnail" style={{ backgroundImage: `url(${item.img_url})` }}>
                      <img src={item.img_url} alt={item.title} />
                    </div>
                    <h3 className="blog-item-title line-clamp" title={item.title}>{item.title}</h3>
                  </a>
                  <p className="blog-item-description line-clamp">{item.description}</p>
                  <a href={item.url} target="_blank" rel="noopender noreferrer" className="hstack gap-1">
                    <span>Read more</span>
                    <span className="icon icon-arrow-right"></span>
                  </a>
                </div>
                ))}
              </div>
            </Col>
            <Col xl={3}>
              <h5>Popular Articles</h5>
              <ul className="garage-money-articles">
                {articles.map((item, idx) => (
                <li key={`article-${idx}`}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                </li>
                ))}
              </ul>
              <a href="https://www.accelerlist.com/blog" target="_blank" rel="noopener noreferrer" className="hstack gap-1">
                <span>More articles</span>
                <span className="icon icon-arrow-right"></span>
              </a>
            </Col>
          </Row>

        </Container>
      </section>
    )
  }
}

export default GarageMoney;
