import {
  all,
  fork,
} from "redux-saga/effects";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* frontTestimonialsGet() {
  yield commonSaga(
    actions.FRONT_GET_TESTIMONIALS,
    apiCalls.get,
    apiEndpoints.homepage.testimonials,
    {...default_config, ...{success_show: false}}
  );
}

export function* fetchInventoryCount() {
  yield commonSaga(
    actions.FETCH_INVENTORY_ITEMS_COUNT,
    apiCalls.get,
    apiEndpoints.homepage.front_item_listed_count,
    {...default_config, ...{success_show: false}}
  );
}

export function* fetchInventoryCountEbay() {
  yield commonSaga(
    actions.FETCH_INVENTORY_ITEMS_COUNT_EBAY,
    apiCalls.get,
    apiEndpoints.homepage.front_item_listed_count_ebay,
    {...default_config, ...{success_show: false}}
  );
}

export function* fetchEbaySalesGraph() {
  yield commonSaga(
    actions.FETCH_EBAY_SALES_GRAPH,
    apiCalls.get,
    apiEndpoints.homepage.ebay_sales_graph_view,
    {...default_config, ...{success_show: false}}
  );
}

export function* fetchHelpArticles() {
  yield commonSaga(
    actions.FETCH_HELP_ARTICLES,
    apiCalls.get,
    apiEndpoints.homepage.help_articles,
    {...default_config, ...{success_show: false}}
  );
}

export default function* frontSaga() {
	yield all([
    fork(frontTestimonialsGet),
    fork(fetchInventoryCount),
    fork(fetchInventoryCountEbay),
    fork(fetchEbaySalesGraph),
    fork(fetchHelpArticles),
	]);
}
