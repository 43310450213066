const adminActions = {
  ADMIN_GET_CATEGORIES: "ADMIN_GET_CATEGORIES",
  ADMIN_ADD_CATEGORY: "ADMIN_ADD_CATEGORY",
  ADMIN_DELETE_CATEGORY: "ADMIN_DELETE_CATEGORY",
  ADMIN_UPDATE_CATEGORY: "ADMIN_UPDATE_CATEGORY",
  ADMIN_ADD_TOPIC: "ADMIN_ADD_TOPIC",
  ADMIN_UPDATE_TOPIC: "ADMIN_UPDATE_TOPIC",
  ADMIN_DELETE_TOPIC: "ADMIN_DELETE_TOPIC",
  ADMIN_GET_USER_ALL: "ADMIN_GET_USER_ALL",
  ADMIN_PRODUCT_UPDATE_CREATE: "ADMIN_PRODUCT_UPDATE_CREATE",
  ADMIN_PRODUCT_UPDATE_ALL: "ADMIN_PRODUCT_UPDATE_ALL",
  ADMIN_PRODUCT_UPDATE_DELETE: "ADMIN_PRODUCT_UPDATE_DELETE",
  ADMIN_PRODUCT_UPDATE_GET: "ADMIN_PRODUCT_UPDATE_GET",
  ADMIN_PRODUCT_UPDATE_UPDATE: "ADMIN_PRODUCT_UPDATE_UPDATE",

  adminProductUpdateUpdate: (data) => ({
    type: adminActions.ADMIN_PRODUCT_UPDATE_UPDATE,
    data,
  }),

  adminProductUpdateGet: (data) => ({
    type: adminActions.ADMIN_PRODUCT_UPDATE_GET,
    data,
  }),

  adminProductUpdateDelete: (data) => ({
    type: adminActions.ADMIN_PRODUCT_UPDATE_DELETE,
    data,
  }),

  adminProductUpdateAll: (data) => ({
    type: adminActions.ADMIN_PRODUCT_UPDATE_ALL,
    data,
  }),

  adminProductUpdateCreate: (data) => ({
    type: adminActions.ADMIN_PRODUCT_UPDATE_CREATE,
    data,
  }),

  adminGetUserAll: (data) => ({
    type: adminActions.ADMIN_GET_USER_ALL,
    data,
  }),

  adminDeleteTopic: (data) => ({
    type: adminActions.ADMIN_DELETE_TOPIC,
    data,
  }),

  adminUpdateTopic: (data) => ({
    type: adminActions.ADMIN_UPDATE_TOPIC,
    data,
  }),

  adminAddTopic: (data) => ({
    type: adminActions.ADMIN_ADD_TOPIC,
    data,
  }),

  adminUpdateCategory: (data) => ({
    type: adminActions.ADMIN_UPDATE_CATEGORY,
    data,
  }),

  adminDeleteCategory: (data) => ({
    type: adminActions.ADMIN_DELETE_CATEGORY,
    data,
  }),

  adminAddCategory: (data) => ({
    type: adminActions.ADMIN_ADD_CATEGORY,
    data,
  }),

  adminGetCategories: (data) => ({
    type: adminActions.ADMIN_GET_CATEGORIES,
    data,
  }),

}

export default adminActions;
