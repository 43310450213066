import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from "reactstrap";
import './style.scss';
import { digitСonversion } from "../../../helpers/utils";

class StatisticBig extends Component {
  render() {
    return (
      <section className="statistic-big">
        <Container>
          <div className="statistic-big-map"></div>
          <div className="statistic-big-text">
            <h2 className="section-title">Amazon sellers trust us with their business</h2>
            <p>Since 2016, thousands of sellers have depended on us to help them grow their business or side hustle…one product at a time.</p>
            <div className="metric-group">
              <div className="metric-item">
                <h3 className="metric-item-value">{digitСonversion(this.props.item_count, "decimal", null, 0)}</h3>
                <span className="metric-item-title"># of products listed</span>
              </div>
              <div className="metric-item">
                <h3 className="metric-item-value">{digitСonversion(this.props.ebay_count?.count || 0, "decimal", null, 0)}</h3>
                <span className="metric-item-title"># of eBay products listed</span>
              </div>
              <div className="metric-item">
                <h3 className="metric-item-value">{digitСonversion(this.props.ebay_count?.sales || 0, "currency", "USD")}</h3>
                <span className="metric-item-title"># of eBay sales generated</span>
              </div>
            </div>
          </div>
        </Container>
      </section>
    )
  }
}

export default connect(
  state => ({
    item_count: state.Front.get("item_count"),
    ebay_count: state.Front.get("ebay_count"),
  }),
  {
  }
)(StatisticBig);
