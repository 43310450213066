import React, { Component } from 'react';
import { Card, CardBody, Container, Table } from "reactstrap";
import { logo } from "../../assets/images";
import './style.css';
import Testimonials from "../Home/Testimonials";

class Alternatives extends Component {
  render() {
    return (
      <div className="inner-page alternatives">
        <div className="inner-page-header">
          <Container>
            <h1 className="inner-page-title">AccelerList Alternatives</h1>
            <p className="inner-page-subtitle">Understand how AccelerList compares to other products out there, so you can find the best fit for you.</p>
          </Container>
        </div>
        <div className="inner-page-content">
          <Container>
            <section>
              <p>Here at AccelerList we were sellers first and we noticed some service gaps that listing applications had. We also understand the need to compare our software to the others out there on the market, so we've made it easier for you.</p>
              <p>Below you'll find a detailed overview of how AccelerList compares to the rest.</p>
              <div className="table-responsive mb-3">
                <Table className="table-alternatives">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th className="table-alternatives-highlight"><img src={logo} width="118" alt="" /></th>
                      <th>ScanLister</th>
                      <th>InventoryLab</th>
                      <th>TurboLister</th>
                      <th>ScanPower</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Pricing</th>
                      <td className="table-alternatives-highlight">From <strong>$39.98</strong>/mo</td>
                      <td>From <strong>$29</strong>/mo</td>
                      <td>From <strong>$70</strong>/mo</td>
                      <td>From <strong>$35</strong>/mo</td>
                      <td>From <strong>$49</strong>/mo</td>
                    </tr>
                    <tr>
                      <th>Repricer</th>
                      <td className="table-alternatives-highlight"><Yes/></td>
                      <td><No/></td>
                      <td><No/></td>
                      <td><No/></td>
                      <td><No/></td>
                    </tr>
                    <tr>
                      <th>Accounting</th>
                      <td className="table-alternatives-highlight"><Yes/></td>
                      <td><No/></td>
                      <td><Yes/></td>
                      <td><No/></td>
                      <td><No/></td>
                    </tr>
                    <tr>
                      <th>eBay Cross listing</th>
                      <td className="table-alternatives-highlight"><Yes/></td>
                      <td><No/></td>
                      <td><No/></td>
                      <td><No/></td>
                      <td><No/></td>
                    </tr>
                    <tr>
                      <th>Mobile App</th>
                      <td className="table-alternatives-highlight"><Yes/></td>
                      <td><No/></td>
                      <td><Yes/></td>
                      <td><Yes/></td>
                      <td><No/></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="mb-4">
                <h2>Why AccelerList?</h2>
                <p>AccelerList is a popular Amazon seller software that offers a wide range of features to help sellers list their products, manage their inventory, and repricer their prices. 
                  Here are some reasons why Amazon sellers might choose AccelerList over its competitors:
                </p>
                <ul>
                  <li>
                    <strong>More features</strong>: AccelerList offers a wider range of features than most of its competitors. In addition to basic listing and inventory management tools, 
                    AccelerList also includes a repricer, accounting tools, and eBay cross-listing capabilities.
                  </li>
                  <li>
                    <strong>More affordable pricing</strong>: AccelerList is more affordable than most of its competitors, especially for sellers with larger inventories. AccelerList also 
                    offers a free trial, so sellers can try the software before they commit to a paid subscription.
                  </li>
                  <li>
                    <strong>Better customer support</strong>: AccelerList has a reputation for providing excellent customer support. Sellers can get help with any questions or problems 
                    they have quickly and easily.
                  </li>
                  <li>
                    <strong>More frequent updates</strong>: AccelerList is constantly being updated with new features and improvements. This shows that the AccelerList team is committed to making the software as good as it can be for its users.
                  </li>
                </ul>
                <p>Here are some specific comparisons between AccelerList and its competitors:</p>
                <ul>
                  <li>
                    <strong>AccelerList vs. Inventory Lab</strong>: Inventory Lab is a more expensive option than AccelerList, and it doesn't offer as many features. For example, Inventory Lab doesn't have a built-in repricer or eBay cross-listing capabilities.
                  </li>
                  <li>
                    <strong>AccelerList vs. ScanLister</strong>: ScanLister is a less expensive option than AccelerList, but it's also less feature-rich. For example, ScanLister doesn't have a built-in repricer or accounting tools.
                  </li>
                  <li>
                    <strong>AccelerList vs. TurboLister</strong>: TurboLister is a free option, but it's only available for eBay sellers. AccelerList is a better option for Amazon sellers because it offers a wider range of features and is more affordable for sellers with larger inventories.
                  </li>
                </ul>
                <p>Overall, AccelerList is a good choice for Amazon sellers who are looking for a comprehensive and affordable software solution. It offers a wide range of features, including a repricer, accounting tools, and eBay cross-listing capabilities. AccelerList is also known for its excellent customer support and frequent updates.</p>
              </div>
              <div className="mb-4">
                <h2>AccelerList Features</h2>
                <p>We have quite a few features to make selling on Amazon easier and we are constantly innovating and adding new features:</p>
                <ul className="list-unstyled">
                  <li><span className="icon icon-check-square bg-success mr-1"></span> Product Listing</li>
                  <li><span className="icon icon-check-square bg-success mr-1"></span> Product Repricing</li>
                  <li><span className="icon icon-check-square bg-success mr-1"></span> Accounting</li>
                  <li><span className="icon icon-check-square bg-success mr-1"></span> Label Printing</li>
                  <li><span className="icon icon-check-square bg-success mr-1"></span> Product Research</li>
                  <li><span className="icon icon-check-square bg-success mr-1"></span> Employee Support</li>
                  <li><span className="icon icon-check-square bg-success mr-1"></span> eBay Cross Listing</li>
                </ul>
              </div>
              <div className="mb-5">
                <h2>Amazing Support</h2>
                <p>We have an amazing global support team ready to help you with any questions or concerns you have while using AccelerList. We jump in quickly and help you get listing again quicker!</p>
                <Testimonials />
              </div>
            </section>

            <section className="detailed-comparisons">
              <div className="section-header">
                <h2 className="section-title">AccelerList vs Others</h2>
                <p className="section-subtitle">Here are some direct comparisons to other products which people frequently migrate to AccelerList from</p>
              </div>
              <div className="comparison-group">
                <Card className="card-comparison">
                  <CardBody>
                    <h3 className="card-comparison-title">AccelerList vs ScanLister</h3>
                    <p>Selling on Amazon is not easy, but software makes it a lot easier and having the right software is crucial in order to scale your business. In this article we are proud to compare ScanLister vs AccelerList so that you can get a comprehensive comparison of the two.</p>
                    <a href="https://accelerlist.com/blog/accelerlist-vs-scanlister/" target="_blank" rel="noopener noreferrer" className="compare-link">Compare AccelerList vs ScanLister</a>
                  </CardBody>
                </Card>
                <Card className="card-comparison">
                  <CardBody>
                    <div>
                      <h3 className="card-comparison-title">AccelerList vs InventoryLab</h3>
                      <p>There is a handful of listing apps around the Amazon universe <span role="img" aria-label="earth">🌎</span> right now. Some are older, and some newer. Some try to help you with data, and some try to help with your workflows. In this article, we're going to give 
                      you a quick overview of the differences between AccelerList and Inventory Lab to help you make a better decision.</p>
                    </div>
                    <a href="https://accelerlist.com/blog/inventory-lab/" target="_blank" rel="noopener noreferrer" className="compare-link">Compare AccelerList vs InventoryLab</a>
                  </CardBody>
                </Card>
                <Card className="card-comparison">
                  <CardBody>
                    <h3 className="card-comparison-title">AccelerList vs TurboLister</h3>
                    <div>
                      <span className="compare-link">Compare AccelerList vs TurboLister</span> <span className="text-muted">(coming soon)</span>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-comparison">
                  <CardBody>
                    <h3 className="card-comparison-title">AccelerList vs ScanPower</h3>
                    <div>
                      <span className="compare-link">Compare AccelerList vs ScanPower</span> <span className="text-muted">(coming soon)</span>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </section>
          </Container>
        </div>
      </div>
    )
  }
}

const Yes = () => (
  <span className="hstack"><span className="icon icon-check-square"></span> Yes</span>
)

const No = () => (
  <span className="hstack"><span className="icon icon-x-square"></span> No</span>
)

export default Alternatives;