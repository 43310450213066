import React, { Component } from 'react';
import { Container } from "reactstrap";
import './style.css';

const data = [
  { 
    id: 1, img_url: "https://blogaccelerlist.wpcomstaging.com/wp-content/uploads/2023/06/nice-house-with-delivery-truck-1.png",
    title: "UPS free pickup UPS Free Pickup for Amazon Sellers: A Step-by-Step Guide", description: "Amazon sellers have much on their plates, from managing inventory to ensuring customer satisfaction. One task that can be particularly time-consuming is arranging for the shipping and delivery of their products.",
    url: "https://www.accelerlist.com/blog/ups-free-pickup/"
  },
  { 
    id: 2, img_url: "https://blogaccelerlist.wpcomstaging.com/wp-content/uploads/2023/05/college-campus-green-al-blog-1-1.png",
    title: "The Ultimate Guide to Selling Textbooks on Amazon: Maximize Your Profits and Minimize Hassles", description: "At the bottom of most of our expert articles, there is usually a great F.A.Q. for readers to get their tough questions answered.",
    url: "https://www.accelerlist.com/blog/the-ultimate-guide-to-selling-textbooks-on-amazon-maximize-your-profits-and-minimize-hassles/"
  },
  { 
    id: 3, img_url: "https://blogaccelerlist.wpcomstaging.com/wp-content/uploads/2023/05/green-package-on-doorstep-accelerlist-1-3.png",
    title: "What to do when Amazon loses your package or it’s stolen", description: "Your Amazon package is yet to arrive in the mail, and you haven’t received any tracking updates for an eternity. You start to wonder if Amazon has lost your package—you might even suspect that you’ve had your Amazon package stolen.",
    url: "https://www.accelerlist.com/blog/amazon-package-stolen/"
  }
]

class Blog extends Component {
  render() {
    return (
      <section className="blog">
        <Container>
          <div className="section-heading hstack justify-content-between">
            <h2 className="section-title">More Amazon Guidance…</h2>
            <div>
              <a href="https://www.accelerlist.com/blog" target="_blank" rel="noopener noreferrer" className="hstack gap-1">
                <span>View all</span>
                <span className="icon icon-arrow-right"></span>
              </a>
            </div>
          </div>
          <div className="blog-posts">
            {data?.map(item => (
            <div className="blog-item" key={`post-${item.id}`}>
              <a href={item.url} target="_blank" rel="noopender noreferrer">
                <div className="blog-item-thumbnail" style={{ backgroundImage: `url(${item.img_url})` }}>
                  <img src={item.img_url} alt={item.title} />
                </div>
                <h3 className="blog-item-title line-clamp" title={item.title}>{item.title}</h3>
              </a>
              <p className="blog-item-description line-clamp">{item.description}</p>
              <a href={item.url} target="_blank" rel="noopender noreferrer" className="hstack gap-1">
                <span>Read more</span>
                <span className="icon icon-arrow-right"></span>
              </a>
            </div>
            ))}
          </div>
        </Container>
      </section>
    )
  }
}

export default Blog;