import React, { Component } from 'react';
import { Bar, BarChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { Button } from 'reactstrap';
import { digitСonversion } from "../../helpers/utils";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="chart-ebay-custom-tooltip">
        <span className="label">{`$${digitСonversion(payload[0].value, "decimal", "USD", 0)} ${moment(label).format('dddd, MMM Do YYYY, hh:mm')}`}</span>
      </div>
    );
  }

  return null;
};

class ChartEbay extends Component {
  state = {
    activeFilter: '1M'
  }

  dateFormatter = (value) => {
    const { activeFilter } = this.state;
    let formattedValue = '';
    if(activeFilter === '1M') {
      formattedValue = moment(value).format('MM-DD');
    } else {
      formattedValue = moment(value).format('YYYY-MM');
    }

    return formattedValue;
  }

  moneyFormat = (value) => {
    return `$${digitСonversion(value, "decimal", "USD", 0)}`;
  }

  tooltipFormatter = (value, name, props) => {
    return this.moneyFormat(value);
  }

  toggleFilter = (filter) => {
    this.setState({ activeFilter: filter })
  }

  render() {
    const { dataDaily } = this.props.data;
    const { activeFilter } = this.state;

    let filteredData = [];
    if (activeFilter === '1M' && dataDaily[activeFilter]) {
      dataDaily[activeFilter].map((item, idx) => {
        if(moment().subtract(1,"month").isSameOrBefore(item.date)) {
          filteredData.push(item)
        }
        return null
      })
    } else if (activeFilter === '1Y' && dataDaily[activeFilter]) {
      dataDaily[activeFilter].map((item, idx) => {
        if(moment().subtract(1,"year").isSameOrBefore(item.date)) {
          filteredData.push(item)
        }
        return null
      })
    } else if (activeFilter === '5Y' && dataDaily[activeFilter]) {
      dataDaily[activeFilter].map((item, idx) => {
        if(moment().subtract(5,"year").isSameOrBefore(item.date)) {
          filteredData.push(item)
        }
        return null
      })
    }

    return (
      <div className="ebay-sales-chart-front">
        <div className="text-center">
          <h3 className="mb-3 lead">Explore the chart below to see how many sales we’ve generated for our sellers with eBay.</h3>
          <div className="hstack gap-2 justify-content-center mb-3">
            <Button color={activeFilter === '1M' ? 'dark' : 'light'} onClick={() => this.toggleFilter('1M')}>1 M</Button>
            <Button color={activeFilter === '1Y' ? 'dark' : 'light'} onClick={() => this.toggleFilter('1Y')}>1 Y</Button>
            <Button color={activeFilter === '5Y' ? 'dark' : 'light'} onClick={() => this.toggleFilter('5Y')}>5 Y</Button>
          </div>
        </div>
        <ResponsiveContainer
          width={"100%"}
          height={300}
        >
          <BarChart
            data={filteredData}
            margin={{
              top: 10,
              right: 30,
              left: 30,
              bottom: 30,
            }}
          >
            <CartesianGrid vertical={false} stroke="#ebeef1" />
            <XAxis
              dataKey="date"
              tick={{ fill: '#535969', fontSize: '12px' }}
              tickLine={false}
              stroke="#ebeef1"
              tickFormatter={this.dateFormatter}
            />
            <YAxis
              axisLine={false}
              tick={{ fill: '#535969', fontSize: '12px' }}
              tickLine={false}
              tickFormatter={this.moneyFormat}
            />
            <Tooltip formatter={this.tooltipFormatter} content={<CustomTooltip />} cursor={{fill: 'transparent'}} />
            <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="97.9950574%" id="areaGradient">
                <stop stopColor="#00943E" stopOpacity="0.6" offset="0%"></stop>
                <stop stopColor="#00943E" stopOpacity="0.1" offset="100%"></stop>
              </linearGradient>
            </defs>
            <Bar barSize={40} dataKey="sales" strokeWidth={0} fill="url(#areaGradient)" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

export default ChartEbay;
