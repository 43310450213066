import React, { Component } from 'react'
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'

class CommunityNav extends Component {
  render() {
    return (
      <Nav className="community-nav">
        <NavItem>
          <NavLink to="/community/questions" className="community-blue">
            <span className="icon icon-chat-bubble" />
            <span className="community-nav-title">Questions</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/community/feature-roadmap" className="community-orange">
            <span className="icon icon-roadmap" />
            <span className="community-nav-title">Feature Roadmap</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/community/product-updates" className="community-green">
            <span className="icon icon-newspaper" />
            <span className="community-nav-title">Product Updates</span>
          </NavLink>
        </NavItem>
        {this.props.userData?.is_admin &&
        <NavItem className="ms-auto">
          <NavLink to="/community/admin">
            <span className="icon icon-admin bg-dark" />
            <span className="community-nav-title">Admin</span>
          </NavLink>
        </NavItem>
        }
      </Nav>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
  }),
  {
  }
)(CommunityNav);
