const frontActions = {
  FRONT_GET_TESTIMONIALS: 'FRONT_GET_TESTIMONIALS',
  FETCH_INVENTORY_ITEMS_COUNT: "FETCH_INVENTORY_ITEMS_COUNT",
  FETCH_INVENTORY_ITEMS_COUNT_EBAY: "FETCH_INVENTORY_ITEMS_COUNT_EBAY",
  FETCH_EBAY_SALES_GRAPH: "FETCH_EBAY_SALES_GRAPH",
  FETCH_HELP_ARTICLES: "FETCH_HELP_ARTICLES",

  fetchHelpArticles: (data) => ({
    type: frontActions.FETCH_HELP_ARTICLES,
    data
  }),

  fetchEbaySalesGraph: (data) => ({
    type: frontActions.FETCH_EBAY_SALES_GRAPH,
    data
  }),

  fetchInventoryCountEbay: (data) => ({
    type: frontActions.FETCH_INVENTORY_ITEMS_COUNT_EBAY,
    data
  }),

  fetchInventoryCount: (data) => ({
    type: frontActions.FETCH_INVENTORY_ITEMS_COUNT,
    data
  }),

  frontTestimonialsGet: (data) => ({
    type: frontActions.FRONT_GET_TESTIMONIALS,
    data
  }),
}

export default frontActions;
