import { Map } from "immutable";

const initState = new Map({
  categories: {},
  categories_working: false,
  users: [],
  users_working: false,
  product_updates: {},
  product_updates_working: false,
  product_update: {},
});

export default function adminReducer(state = initState, action) {
  switch (action.type) {

    case "ADMIN_PRODUCT_UPDATE_UPDATE":
      return state
        .set("product_update", {})
        .set("product_updates_working", true)
    case "ADMIN_PRODUCT_UPDATE_UPDATE_SUCCESS":
      var update_product_updates = state.get("product_updates");
      update_product_updates.items = update_product_updates.items.map(item => {
        if(item.id === action.data.data.id){
          return action.data.data;
        }
        return item;
      });
      return state
        .set("product_updates", {...update_product_updates})
        .set("product_update", {})
        .set("product_updates_working", false)
    case "ADMIN_PRODUCT_UPDATE_UPDATE_FAILED":
      return state
        .set("product_update", {})
        .set("product_updates_working", false)

    case "ADMIN_PRODUCT_UPDATE_GET":
      return state
        .set("product_update", {})
        .set("product_updates_working", true)
    case "ADMIN_PRODUCT_UPDATE_GET_SUCCESS":
      return state
        .set("product_update", {...action.data.data})
        .set("product_updates_working", false)
    case "ADMIN_PRODUCT_UPDATE_GET_FAILED":
      return state
        .set("product_update", {})
        .set("product_updates_working", false)

    case "ADMIN_PRODUCT_UPDATE_DELETE":
      return state
        .set("product_updates_working", true)
    case "ADMIN_PRODUCT_UPDATE_DELETE_SUCCESS":
      var delete_product_updates = state.get("product_updates");
      delete_product_updates.items = delete_product_updates.items.filter(item => item.id !== action.data.data.id);
      return state
        .set("product_updates", {...delete_product_updates})
        .set("product_updates_working", false)
    case "ADMIN_PRODUCT_UPDATE_DELETE_FAILED":
      return state
        .set("product_updates_working", false)

    case "ADMIN_PRODUCT_UPDATE_ALL":
      return state
        .set("product_updates", {})
        .set("product_updates_working", true)
    case "ADMIN_PRODUCT_UPDATE_ALL_SUCCESS":
      return state
        .set("product_updates", {...action.data.data})
        .set("product_updates_working", false)
    case "ADMIN_PRODUCT_UPDATE_ALL_FAILED":
      return state
        .set("product_updates", {})
        .set("product_updates_working", false)

    case "ADMIN_PRODUCT_UPDATE_CREATE":
      return state
        .set("product_updates_working", true)
    case "ADMIN_PRODUCT_UPDATE_CREATE_SUCCESS":
      var create_product_updates = state.get("product_updates");
      if(create_product_updates?.items){
        create_product_updates.items.unshift(action.data.data);
      } else {
        create_product_updates.items = [action.data.data];
      }
      return state
        .set("product_updates", [...create_product_updates])
        .set("product_updates_working", false)
    case "ADMIN_PRODUCT_UPDATE_CREATE_FAILED":
      return state
        .set("product_updates_working", false)

    case "ADMIN_GET_USER_ALL":
      return state
        .set("users_working", true)
    case "ADMIN_GET_USER_ALL_SUCCESS":
      return state
        .set("users", [...action.data.data])
        .set("users_working", false)
    case "ADMIN_GET_USER_ALL_FAILED":
      return state
        .set("users_working", false)

    case "ADMIN_DELETE_TOPIC":
      return state
        .set("categories_working", true)
    case "ADMIN_DELETE_TOPIC_SUCCESS":
      var topic_delete = state.get("categories");
      delete topic_delete[action.data.data.id_category].topics[action.data.data.id];
      return state
        .set("categories", {...topic_delete})
        .set("categories_working", false)
    case "ADMIN_DELETE_TOPIC_FAILED":
      return state
        .set("categories_working", false)

    case "ADMIN_UPDATE_TOPIC":
      return state
        .set("categories_working", true)
    case "ADMIN_UPDATE_TOPIC_SUCCESS":
      var topic_update = state.get("categories");
      topic_update[action.data.data.id_category].topics[action.data.data.id] = {
        ...topic_update[action.data.data.id_category].topics[action.data.data.id],
        ...action.data.data
      };
      return state
        .set("categories", {...topic_update})
        .set("categories_working", false)
    case "ADMIN_UPDATE_TOPIC_FAILED":
      return state
        .set("categories_working", false)

    case "ADMIN_ADD_TOPIC":
      return state
        .set("categories_working", true)
    case "ADMIN_ADD_TOPIC_SUCCESS":
      var topic_add = state.get("categories");
      topic_add[action.data.data.id_category].topics[action.data.data.id] = {...action.data.data};
      return state
        .set("categories", {...topic_add})
        .set("categories_working", false)
    case "ADMIN_ADD_TOPIC_FAILED":
      return state
        .set("categories_working", false)

    case "ADMIN_UPDATE_CATEGORY":
      return state
        .set("categories_working", true)
    case "ADMIN_UPDATE_CATEGORY_SUCCESS":
      var categories_update = state.get("categories");
      categories_update[action.data.data.id].name = action.data.data.name;
      return state
        .set("categories", {...categories_update})
        .set("categories_working", false)
    case "ADMIN_UPDATE_CATEGORY_FAILED":
      return state
        .set("categories_working", false)

    case "ADMIN_DELETE_CATEGORY":
      return state
        .set("categories_working", true)
    case "ADMIN_DELETE_CATEGORY_SUCCESS":
      var categories_delete = state.get("categories");
      Object.keys(categories_delete[action.data.data.id].topics).forEach(key => {
        categories_delete[1].topics[key] = {...categories_delete[action.data.data.id].topics[key]}
      })
      delete categories_delete[action.data.data.id];
      return state
        .set("categories", {...categories_delete})
        .set("categories_working", false)
    case "ADMIN_DELETE_CATEGORY_FAILED":
      return state
        .set("categories_working", false)

    case "ADMIN_ADD_CATEGORY":
      return state
        .set("categories_working", true)
    case "ADMIN_ADD_CATEGORY_SUCCESS":
      var categories_add = state.get("categories");
      categories_add[action.data.data.id] = {...action.data.data}
      categories_add[action.data.data.id].topics = {};
      return state
        .set("categories", {...categories_add})
        .set("categories_working", false)
    case "ADMIN_ADD_CATEGORY_FAILED":
      return state
        .set("categories_working", false)

    case "ADMIN_GET_CATEGORIES":
      return state
        .set("categories_working", true)
    case "ADMIN_GET_CATEGORIES_SUCCESS":
      return state
        .set("categories", {...action.data.data})
        .set("categories_working", false)
    case "ADMIN_GET_CATEGORIES_FAILED":
      return state
        .set("categories_working", false)

		default:
			return state;
  }
}
