import { Badge } from "reactstrap";

const statusClassMap = {
  'UNDER_REVIEW': 'info',
  'IN_PROGRESS': 'warning',
  'COMPLETE': 'success',
}

const StatusBadge = ({ status}) => (
  <Badge color={statusClassMap[status]}>{status?.replace("_", " ").toUpperCase()}</Badge>
)
export default StatusBadge
