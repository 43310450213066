import React, { Component } from 'react'
import { connect } from "react-redux";
import Editor from '@draft-js-plugins/editor'
import 'draft-js/dist/Draft.css'
import {
  AtomicBlockUtils,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertToRaw,
  Modifier,
} from "draft-js"
import gkx from 'draft-js/lib/gkx'
import NestedRichTextEditorUtil from 'draft-js/lib/NestedRichTextEditorUtil'
import createLinkDetectionPlugin from 'draft-js-link-detection-plugin';
import createImagePlugin from '@draft-js-plugins/image'
import '@draft-js-plugins/image/lib/plugin.css'
import { Button, FormGroup, Input } from "reactstrap"
import Select from "react-select"
import communityActions from "../../redux/community/actions";
import { wordsMap } from "./words-map";

const {
  communityCreateQuestion,
} = communityActions;

const RichTextUtils = gkx('draft_tree_data_support')
  ? NestedRichTextEditorUtil
  : RichUtils;

const linkDetectionPlugin = createLinkDetectionPlugin();
const imagePlugin = createImagePlugin()

class PostForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      topic: null,
      title: '',
      editorState: EditorState.createEmpty()
    }
  }

  createQuestion = () => {
    const { topic, title, editorState } = this.state;
    const data = {
      id_category: topic.category_id,
      id_topic: topic.value,
      title: title,
      question: convertToRaw(editorState.getCurrentContent()),
    }
    console.log("createQuestion:", data);
    this.props.communityCreateQuestion(data);
    this.props.toggle();
  }

  onChange = (editorState) => {
    this.setState({ editorState })
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichTextUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichTextUtils.onTab(
        e,
        this.state.editorState,
        4 /* maxDepth */,
      );
      if (newEditorState !== this.state.editorState) {
        this.onChange(newEditorState);
      }
      return false;
    }
    return getDefaultKeyBinding(e);
  }

  toggleBlockType = (blockType) => {
    this.onChange(
      RichTextUtils.toggleBlockType(this.state.editorState, blockType),
    )
  }

  toggleInlineStyle = (inlineStyle) => {
    this.onChange(
      RichTextUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
    )
  }

  insertImage = (url) => {
    const editorState = this.state.editorState
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(
        'IMAGE',
        'IMMUTABLE',
        { src: url },
      )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set( editorState, { currentContent: contentStateWithEntity });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  }

  readUploadedFileAsBase64 = (inputFile) => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      }
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.readAsDataURL(inputFile);
    })
  }

  onChangeFile = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const uploadedFiles = e.target.files;
    const imageBase64 = await this.readUploadedFileAsBase64(uploadedFiles[0])
    const newEditorState = this.insertImage(imageBase64)
    this.onChange(newEditorState)
  }

  isEditorEmpty = () => {
    const editorState = this.state.editorState
    return !editorState.getCurrentContent().hasText()
    && editorState.getCurrentContent().getPlainText().length <= 0
  }

  generateTopics = () => {
    const { categories } = this.props;
    if(!categories){ return [] }

    return Object.keys(categories).map(cat_key => {
      return {
        label: categories[cat_key]?.name,
        options: Object.keys(categories[cat_key]?.topics).map(top_key => {
            return {
              label: categories[cat_key]?.topics[top_key].name,
              value: top_key,
              category_id: cat_key,
            }
        })
      }
    })
  }

  replaceString = (chars, editorState) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const block = contentState.getBlockForKey( selectionState.getAnchorKey() );

    Object.keys(wordsMap).map(findText => {
      if ( block.getText().indexOf( findText ) !== -1 ) {
          const currentSelectionState = this.state.editorState.getSelection();

          const newContentState = Modifier.replaceText(
            contentState,
            selectionState.merge( {
              anchorOffset: currentSelectionState.getEndOffset() - findText.length,
              focusOffset: currentSelectionState.getEndOffset()
            } ),
            wordsMap[findText]
          );

          this.setState({
            editorState: EditorState.push(
              editorState,
              newContentState,
              'replace-text'
          )})
      }
      return null
    })
  }


  render() {
    const { topic, title, editorState } = this.state
    const { placeholder, questions_working } = this.props

    return (
      <div className="post-form">
        <FormGroup>
          <Select
            options={this.generateTopics()}
            placeholder="Please select a topic"
            value={topic}
            onChange={(selected) => this.setState({ topic: selected })}
          />
        </FormGroup>
        <FormGroup>
          <Input
            placeholder="Title"
            value={title}
            onChange={(e) => this.setState({ title: e.target.value })}
          />
        </FormGroup>
        <div className="editor" onClick={() => this.editor.focus()}>
          <Editor
            ref={el => this.editor = el }
            editorState={editorState}
            blockStyleFn={getBlockStyle}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            onChange={(editorState) => this.setState({ editorState })}
            spellCheck={true}
            placeholder={placeholder ? placeholder : 'Type here...'}
            plugins={[linkDetectionPlugin, imagePlugin]}
            handleBeforeInput={ this.replaceString }
          />
          <div className="editor-controls">
            <div className="hstack justify-content-between gap-1">
              <InlineStyleControls
                editorState={editorState}
                onToggle={this.toggleInlineStyle}
              />
              <BlockStyleControls
                editorState={editorState}
                onToggle={this.toggleBlockType}
              />
              <span
                className="editor-controls-button"
                onClick={() => { this.upload.click() }}
              >
                <span className="icon icon-image" />
                <input
                  type="file"
                  id="imageFile"
                  className="d-none"
                  ref={(ref) => this.upload = ref}
                  accept={"image/png, image/jpeg"}
                  onChange={this.onChangeFile.bind(this)}
                />
              </span>
            </div>
          </div>
        </div>
        <Button
          color="primary"
          disabled={this.isEditorEmpty() || title === '' || !topic || questions_working}
          onClick={() => this.createQuestion()}
        >Submit</Button>
      </div>
    )
  }
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'code-block':
      return 'editor-code';
    default:
      return null;
  }
}

function StyleButton({active, style, label, onToggle}) {
  let className = 'editor-controls-button';
  if (active) {
    className += ' active';
  }

  return (
    <span
      className={className}
      onMouseDown={(e) => {
        e.preventDefault();
        onToggle(style);
      }}
    >
      {label}
    </span>
  );
}

const BLOCK_TYPES = [
  {label: '<>', style: 'code-block'},
];

function BlockStyleControls(props) {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <React.Fragment>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.style}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </React.Fragment>
  )
}

const INLINE_STYLES = [
  {label: <b>B</b>, style: 'BOLD'},
  {label: <em>I</em>, style: 'ITALIC'},
];

function InlineStyleControls(props) {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <React.Fragment>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </React.Fragment>
  )
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    questions_working: state.Community.get("questions_working"),
  }),
  {
    communityCreateQuestion,
  }
)(PostForm);
