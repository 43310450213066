import React, { Component } from 'react';
import { Container } from "reactstrap";
import Slider from 'react-slick';
import { appStore, googlePlay, phCalculation, phHome, phResult, qrcodePH, scanThingsBlack } from "../../../assets/images";
import './style.css';

const features1 = [
  {title: 'Profit Score™', description: 'Measures how often a book has sold in the past 6 months.'},
  {title: 'Downloadable Database', description: 'Scan books faster without the need for an internet connection.'},
  {title: 'Wholesale', description: 'Earn guaranteed profit by selling your books directly to a wholesaler.'},
  {title: 'Smart Triggers', description: 'Accept or reject buy decisions based on profit triggers you create.'},
]

const features2 = [
  {title: 'Speed Mode', description: 'Scan barcodes continuously only using your phones camera. More scans = More Profit.'},
  {title: 'Direct Listing', description: 'Stop using separate listing applications! Scan and list your items directly into a batch thats waiting for you.'},
  {title: 'Advanced Accounting', description: 'Know exactly the impact your scanning is having on your Amazon business.'},
  {title: 'Team mode', description: 'Add teams or additional sourcers and create your own army of profitable scanners.'},
]

class ProfitHunt extends Component {
  render() {
    return (
      <section className="profit-hunt">
        <Container>
          <div className="section-heading">
            <h2 className="section-title">Stop "scouting" and start hunting... with <span className="text-success">Profit Hunt</span>™</h2>
          </div>
          <div className="text-center">
            <div className="profit-hunt-scan">
              <img src={scanThingsBlack} alt="Scan things at Profit Hunt™" className="img-fluid" />
            </div>
          </div>
          <div className="profit-hunt-content">
            <div className="profit-hunt-features">
              {features1.map((item, idx) => (
              <div className="profit-hunt-feature-item" key={`feature1-${idx}`}>
                <h3 className="profit-hunt-feature-title">{item.title}</h3>
                <p className="profit-hunt-feature-description">{item.description}</p>
              </div>
              ))}
            </div>
            <div className="profit-hunt-app">
              <Slider
                arrows={false}
                dots={true}
                infinite={true}
                slidesToShow={1}
                slidesToScroll={1}
                autoplay
              >
                <div>
                  <img src={phHome} alt="Porfit Hunt Home" className="img-fluid mx-auto" />
                </div>
                <div>
                  <img src={phResult} alt="Profit Hunt Search Result" className="img-fluid mx-auto" />
                </div>
                <div>
                  <img src={phCalculation} alt="Profit Hunt Calculation" className="img-fluid mx-auto" />
                </div>
              </Slider>
            </div>
            <div className="profit-hunt-features">
            {features2.map((item, idx) => (
              <div className="profit-hunt-feature-item" key={`feature2-${idx}`}>
                <h3 className="profit-hunt-feature-title">{item.title}</h3>
                <p className="profit-hunt-feature-description">{item.description}</p>
              </div>
              ))}
            </div>
          </div>
          <div className="d-flex gap-3 mt-4 justify-content-center">
            <img src={qrcodePH} alt="QR Code for Profit Hunt™" width={112} height={134} />
            <div className="profit-hunt-download">
              <a href="https://play.google.com/store/apps/details?id=com.profithunt" target="_blank" rel="noopener noreferrer">
                <img src={googlePlay} alt="Profit Hunt on Google Play" className="img-fluid" />
              </a>
              <a href="https://apps.apple.com/us/app/profithunt/id1484365065" target="_blank" rel="noopener noreferrer">
                <img src={appStore} alt="Profit Hunt on Google Play" className="img-fluid" />
              </a>
            </div>
          </div>
        </Container>
      </section>
    )
  }
}

export default ProfitHunt;