import React, { Component } from 'react'
import { connect } from "react-redux";
import Editor from '@draft-js-plugins/editor'
import 'draft-js/dist/Draft.css'
import {
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertToRaw,
  convertFromRaw,
  Modifier,
} from "draft-js"
import gkx from 'draft-js/lib/gkx'
import NestedRichTextEditorUtil from 'draft-js/lib/NestedRichTextEditorUtil'
import createLinkDetectionPlugin from 'draft-js-link-detection-plugin';
import '@draft-js-plugins/image/lib/plugin.css'
import { Button, Col, FormGroup, Input, Label } from "reactstrap"
import { wordsMap } from "../../Community/words-map.js";
import Select from 'react-select'
import adminActions from "../../../redux/admin/actions";
import communityActions from "../../../redux/community/actions";
import CreatableSelect from 'react-select/creatable';

const {
  adminProductUpdateCreate,
  adminProductUpdateUpdate,
} = adminActions;

const {
  roadmapUploadImage,
  roadmapClearImage,
  roadmapLoadImage,
} = communityActions;

const RichTextUtils = gkx('draft_tree_data_support')
  ? NestedRichTextEditorUtil
  : RichUtils;

const linkDetectionPlugin = createLinkDetectionPlugin();

const FILE_UPLOAD_LIMIT_NUM = 5 // Megabytes
const FILE_UPLOAD_LIMIT = FILE_UPLOAD_LIMIT_NUM * 1048576

const status_options = [
  {value: true, label: 'Published'},
  {value: false, label: 'Draft'},
]

class AdminPostForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editorState: EditorState.createEmpty(),
      img_url: null,
      selected_status: status_options[0],
      title: "",
      tags:[]
    }
  }

  onChange = (editorState) => {
    this.setState({ editorState })
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichTextUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichTextUtils.onTab(
        e,
        this.state.editorState,
        4 /* maxDepth */,
      );
      if (newEditorState !== this.state.editorState) {
        this.onChange(newEditorState);
      }
      return false;
    }
    return getDefaultKeyBinding(e);
  }

  toggleBlockType = (blockType) => {
    this.onChange(
      RichTextUtils.toggleBlockType(this.state.editorState, blockType),
    )
  }

  toggleInlineStyle = (inlineStyle) => {
    this.onChange(
      RichTextUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
    )
  }

  isEditorEmpty = () => {
    const editorState = this.state.editorState
    return !editorState.getCurrentContent().hasText()
    && editorState.getCurrentContent().getPlainText().length <= 0
  }

  replaceString = (chars, editorState) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const block = contentState.getBlockForKey( selectionState.getAnchorKey() );

    Object.keys(wordsMap).map(findText => {
      if ( block.getText().indexOf( findText ) !== -1 ) {
          const currentSelectionState = this.state.editorState.getSelection();

          const newContentState = Modifier.replaceText(
            contentState,
            selectionState.merge( {
              anchorOffset: currentSelectionState.getEndOffset() - findText.length,
              focusOffset: currentSelectionState.getEndOffset()
            } ),
            wordsMap[findText]
          );

          this.setState({
            editorState: EditorState.push(
              editorState,
              newContentState,
              'replace-text'
          )})
      }
      return null
    })
  }

  onImageUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const uploadedFiles = e.target.files;

    let timer = null
    if(uploadedFiles[0]) {
      if(uploadedFiles[0].size > FILE_UPLOAD_LIMIT) {
        this.setState({ fileSizeError: true })
        timer = setTimeout(() => {
          this.setState({ fileSizeError: false })
          clearTimeout(timer)
        }, 4000)
      } else {
        // upload image and set the URL for preview
        var formData = new FormData();
        formData.append(
          "file",
          uploadedFiles[0]
        );
        this.props.roadmapUploadImage(formData);
      }
    }
  }

  adminProductUpdateCreate = () => {
    const { editorState, title, selected_status, tags } = this.state;

    const data = {
      title: title,
      text: convertToRaw(editorState.getCurrentContent()),
      published: selected_status?.value,
      image: this.props.roadmap_image,
      tags: tags ? tags.map(item => {return item.value;}).join(",") : "",
    }
    this.props.adminProductUpdateCreate(data);
    this.props.roadmapClearImage();
    this.setState({
      title: "",
      published: status_options[0],
      editorState: EditorState.createEmpty(),
      tags: [],
    })
    this.props.onCancel();
  }

  componentDidUpdate = (pp) => {
    const { product_update } = this.props;

    if(pp?.product_update !== product_update){
      this.setState({
        title: product_update?.title,
        selected_status: status_options.find(item => item.value === product_update?.published),
        editorState: EditorState.createWithContent(convertFromRaw(product_update?.text)),
        tags: product_update?.tags ? product_update?.tags_dict?.map(item => {return {value: item, label: item}}) : [],
      })
      this.props.roadmapLoadImage({url: product_update?.image})
    }
  }

  adminProductUpdateUpdate = () => {
    const { editorState, title, selected_status, tags } = this.state;

    const data = {
      title: title,
      text: convertToRaw(editorState.getCurrentContent()),
      published: selected_status?.value,
      image: this.props.roadmap_image,
      tags: tags ? tags.map(item => {return item.value;}).join(",") : "",
    }
    this.props.adminProductUpdateUpdate({
      id: this.props.product_update?.id,
      update_data: data
    });
    this.props.roadmapClearImage();
    this.setState({
      title: "",
      selected_status: status_options[0],
      editorState: EditorState.createEmpty(),
      tags: [],
    })
    this.props.onCancel();
  }

  render() {
    const { editorState, selected_status, title, tags } = this.state
    const {
      data,
      onCancel,
      product_updates_working,
      roadmap_image,
    } = this.props

    return (
      <div className="admin admin-product-updates">
        <div className="mb-4">
          <Button color="link" onClick={onCancel}>&larr; Back</Button>
          <h3 className="h5 mb-0">{data === null ? 'Create new' : 'Edit'} post</h3>
        </div>
        <FormGroup>
          <Label>Title</Label>
          <Input
            placeholder="Post title"
            value={title}
            onChange={(e) => this.setState({title: e.target.value})}
          />
        </FormGroup>
        <FormGroup row>
          <Col md={2}>
            <Label>Status</Label>
            <div style={{ maxWidth: 200 }}>
              <Select
                options={status_options}
                value={selected_status}
                onChange={selected => this.setState({ selected_status: selected })}
                isClearable={false}
                isSearchable={false}
              />
            </div>
          </Col>
          <Col md={10}>
            <Label>Select tags</Label>
            <CreatableSelect
              isMulti
              options={[
                {value: 'listing', label: 'listing'},
                {value: 'box content', label: 'box content'},
                {value: 'shipment', label: 'shipment'},
              ]}
              value={tags}
              onChange={(e) => this.setState({tags: [...e]})}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Label>Upload an image</Label>
          {roadmap_image &&
          <div className="mb-3">
            <img src={roadmap_image} alt="" className="img-fluid" style={{ maxHeight: 300 }}  />
          </div>
          }
          <div className="hstack gap-2">
            <Button
              color="secondary"
              onClick={() => {
                this.upload.click()
              }}
            >Upload</Button>
            {roadmap_image &&
            <Button
              color="link"
              className="text-danger"
              onClick={() => this.props.roadmapClearImage()}
            >
              Remove image
            </Button>
            }
            <input
              type="file"
              id="imageFile"
              className="d-none"
              ref={(ref) => this.upload = ref}
              accept={"image/png, image/jpeg"}
              onChange={this.onImageUpload.bind(this)}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label>Content</Label>
          <div className="editor" onClick={() => this.editor.focus()}>
            <Editor
              ref={el => this.editor = el }
              editorState={editorState}
              blockStyleFn={getBlockStyle}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.mapKeyToEditorCommand}
              onChange={(editorState) => this.setState({ editorState })}
              spellCheck={true}
              placeholder="Write product updates here..."
              plugins={[linkDetectionPlugin]}
              handleBeforeInput={ this.replaceString }
            />
            <div className="editor-controls">
              <div className="hstack justify-content-between gap-1">
                <BlockStyleControls
                  editorState={editorState}
                  onToggle={this.toggleBlockType}
                />
                <InlineStyleControls
                  editorState={editorState}
                  onToggle={this.toggleInlineStyle}
                />
              </div>
            </div>
          </div>
        </FormGroup>
        {data === null
          ?
            <Button
              color="primary"
              disabled={product_updates_working}
              onClick={() => this.adminProductUpdateCreate()}
            >Save changes</Button>
          :
            <Button
              color="primary"
              disabled={product_updates_working}
              onClick={() => this.adminProductUpdateUpdate()}
            >Update changes</Button>
        }
      </div>
    )
  }
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'code-block':
      return 'editor-code';
    default:
      return null;
  }
}

function StyleButton({active, style, label, onToggle}) {
  let className = 'editor-controls-button';
  if (active) {
    className += ' active';
  }

  return (
    <span
      className={className}
      onMouseDown={(e) => {
        e.preventDefault();
        onToggle(style);
      }}
    >
      {label}
    </span>
  );
}

const BLOCK_TYPES = [
  {label: 'H1', style: 'header-one'},
  {label: 'H2', style: 'header-two'},
  {label: 'H3', style: 'header-three'},
  {label: 'H4', style: 'header-four'},
  {label: 'H5', style: 'header-five'},
  {label: 'H6', style: 'header-six'},
];

function BlockStyleControls(props) {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <React.Fragment>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.style}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </React.Fragment>
  )
}

const INLINE_STYLES = [
  {label: <b>B</b>, style: 'BOLD'},
  {label: <em>I</em>, style: 'ITALIC'},
];

function InlineStyleControls(props) {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <React.Fragment>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </React.Fragment>
  )
}

export default connect(
  state => ({
    product_updates: state.Admin.get("product_updates"),
    product_updates_working: state.Admin.get("product_updates_working"),
    roadmap_image: state.Community.get("roadmap_image"),
    product_update: state.Admin.get("product_update"),
  }),
  {
    adminProductUpdateCreate,
    adminProductUpdateUpdate,
    roadmapUploadImage,
    roadmapClearImage,
    roadmapLoadImage,
  }
)(AdminPostForm);
