import { Spinner } from "reactstrap"

const Loading = ({ title, className, style }) => (
  <div
    className={`loading-indicator text-center ${className}`}
    style={style}
  >
    <Spinner color="primary" />
    <div>{title ? title : 'Loading...'}</div>
  </div>
)

export default Loading