import React, { Component } from 'react'
import { Container } from 'reactstrap';
import CommunityNav from './CommunityNav';
import { Outlet } from 'react-router';
import './style.css'

class Community extends Component {
  render() {
    return (
      <div className="inner-page community">
        <Container>
          <div className="inner-page-nav">
            <CommunityNav />
          </div>
          <Outlet />
        </Container>
      </div>
    )
  }
}

export default Community;
