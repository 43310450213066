import adminSagas from "./admin/saga";

import { all } from "redux-saga/effects";
import frontSagas from "./front/saga";
import userSagas from "./user/saga";
import authSagas from "./auth/saga";
import communitySagas from "./community/saga";

export default function* rootSaga(getState) {
  yield all([
    frontSagas(),
    userSagas(),
    authSagas(),
    communitySagas(),
    adminSagas(),
  ]);
}
