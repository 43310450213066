import { all, fork } from "redux-saga/effects";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* adminGetCategories() {
  yield commonSaga(
    actions.ADMIN_GET_CATEGORIES,
    apiCalls.get,
    apiEndpoints.admin.category_all,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* adminAddCategory() {
  yield commonSaga(
    actions.ADMIN_ADD_CATEGORY,
    apiCalls.post,
    apiEndpoints.admin.category,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* adminDeleteCategory() {
  yield commonSaga(
    actions.ADMIN_DELETE_CATEGORY,
    apiCalls.delete,
    apiEndpoints.admin.category,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* adminUpdateCategory() {
  yield commonSaga(
    actions.ADMIN_UPDATE_CATEGORY,
    apiCalls.put,
    apiEndpoints.admin.category,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* adminAddTopic() {
  yield commonSaga(
    actions.ADMIN_ADD_TOPIC,
    apiCalls.post,
    apiEndpoints.admin.topic,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* adminUpdateTopic() {
  yield commonSaga(
    actions.ADMIN_UPDATE_TOPIC,
    apiCalls.put,
    apiEndpoints.admin.topic,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* adminDeleteTopic() {
  yield commonSaga(
    actions.ADMIN_DELETE_TOPIC,
    apiCalls.delete,
    apiEndpoints.admin.topic,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* adminGetUserAll() {
  yield commonSaga(
    actions.ADMIN_GET_USER_ALL,
    apiCalls.get,
    apiEndpoints.admin.user_all,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* adminProductUpdateCreate() {
  yield commonSaga(
    actions.ADMIN_PRODUCT_UPDATE_CREATE,
    apiCalls.post,
    apiEndpoints.admin.product_update,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* adminProductUpdateAll() {
  yield commonSaga(
    actions.ADMIN_PRODUCT_UPDATE_ALL,
    apiCalls.get,
    apiEndpoints.admin.product_update_all,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* adminProductUpdateDelete() {
  yield commonSaga(
    actions.ADMIN_PRODUCT_UPDATE_DELETE,
    apiCalls.delete,
    apiEndpoints.admin.product_update,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* adminProductUpdateGet() {
  yield commonSaga(
    actions.ADMIN_PRODUCT_UPDATE_GET,
    apiCalls.get,
    apiEndpoints.admin.product_update,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* adminProductUpdateUpdate() {
  yield commonSaga(
    actions.ADMIN_PRODUCT_UPDATE_UPDATE,
    apiCalls.put,
    apiEndpoints.admin.product_update,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export default function* adminSaga() {
	yield all([
    fork(adminGetCategories),
    fork(adminAddCategory),
    fork(adminDeleteCategory),
    fork(adminUpdateCategory),
    fork(adminAddTopic),
    fork(adminUpdateTopic),
    fork(adminDeleteTopic),
    fork(adminGetUserAll),
    fork(adminProductUpdateCreate),
    fork(adminProductUpdateAll),
    fork(adminProductUpdateDelete),
    fork(adminProductUpdateGet),
    fork(adminProductUpdateUpdate),
	]);
}
