import React, { Component } from 'react'
import { Modal } from "reactstrap"

class ImageModal extends Component {
  render() {
    const { isOpen, toggle, img_url  } = this.props

    return (
      <Modal size="xl" isOpen={isOpen} toggle={toggle} centered fade={false} modalClassName="image-modal">
        <span
          role="button"
          style={{
            position: 'absolute',
            top: '-.5rem',
            right: '-1.5rem',
            fontSize: '1.5rem',
          }}
          onClick={toggle}
        >&#x2715;</span>
        <div className="mx-auto">
          <img src={img_url} alt="" className="img-fluid" />
        </div>
      </Modal>
    )
  }
}

export default ImageModal