import { Map } from "immutable";

export function saveTokenLocal(data){
	//save token local
	if(data.access_token){
		localStorage.setItem("access_token", data.access_token);
	}
	if(data.refresh_token){
		localStorage.setItem("refresh_token", data.refresh_token);
	}
}

export function clearTokenLocal() {
	//clear token local
	localStorage.removeItem("access_token");
	localStorage.removeItem("refresh_token");
}

export function getTokenLocal() {
	try {
		const access_token = localStorage.getItem("access_token");
		const refresh_token = localStorage.getItem("refresh_token");
		return new Map({ access_token, refresh_token });
	} catch (err) {
		clearTokenLocal();
		return new Map();
	}
}
