import React, { Component } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import AdminUsers from "./AdminUsers"
import './style.css'
import AdminCategoriesTopics from "./AdminCategoriesTopics"
import AdminProductUpdates from "./AdminProductUpdates"

class CommunityAdmin extends Component {
  state = {
    activeTab: "1"
  }

  render() {
    const { activeTab } = this.state

    return (
      <React.Fragment>
        <div className="inner-page-header">
          <h1 className="inner-page-title">Admin</h1>
        </div>
        <div className="inner-page-content">
          <Nav className="community-nav">
            <NavItem>
              <NavLink
                role="button"
                active={activeTab === "1"}
                onClick={() => this.setState({ activeTab: "1" })}
              >
                Users
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                role="button"
                active={activeTab === "2"}
                onClick={() => this.setState({ activeTab: "2" })}
              >
                Categories & Topics
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                role="button"
                active={activeTab === "3"}
                onClick={() => this.setState({ activeTab: "3" })}
              >
                Product Updates
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {activeTab === "1" && <AdminUsers />}
            </TabPane>
            <TabPane tabId="2">
              {activeTab === "2" && <AdminCategoriesTopics />}
            </TabPane>
            <TabPane tabId="3">
              {activeTab === "3" && <AdminProductUpdates />}
            </TabPane>
          </TabContent>
        </div>
      </React.Fragment>
    )
  }
}

export default CommunityAdmin;
