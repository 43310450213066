import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import './style.css';
import Testimonials from "../Home/Testimonials";
import VideoPlayer from "../../shared/components/VideoPlayer";
import { Helmet } from "react-helmet";

const videosRow1 = [
  { id: 'URtBPXhaZiQNrUdPIwLNEaSkqucd00xEnV01rcOMCb3d8', title: "Johanna R" },
  { id: 'GZZtGfcHO800HFGohDnYlrCuzQDYOCzW007C01R9c6FSoU', title: "Patrick S" },
  { id: 'axNh7pL8shsITXfFPYq4AS5Wsl00OWQeNlh00IKn4aeV4', title: "Jarrod A" },
]

const videosRow2 = [
  { id: '9gxB6VEMgSd7xshXkdo7QYu7Hu021fY6WHm01H7qQGQQM', title: "Jennifer E" },
  { id: 'pHo5zwLN4sgVqEeGAJfeAyd02zbYabpNg9zRz9GeNeYg', title: "Joshua N" },
  { id: 'ofFSTzJfC9AmXihZ25jcKConF619b3zgYZnV1g6GXQ4', title: "Theresa M" },
]

class Affiliates extends Component {
  render() {
    return (
      <div className="inner-page page-affiliates">
        <Helmet>
          <title>Affiliates 🔥 | AccelerList</title>
          <meta name="description" content="Earn $10/mo, FOREVER. Flat, no caps, as long as your referral keeps using AccelerList" />
        </Helmet>
        <div className="inner-page-header">
          <Container>
            <h1 className="inner-page-title">Earn $10/mo, <span className="highlight-yellow">FOREVER</span></h1>
            <p className="inner-page-subtitle">Flat, no caps, as long as your referral keeps using AccelerList</p>
          </Container>
        </div>
        <div className="inner-page-content">
          <Container>
            <div className="affiliate-signup">
              <h2 className="affiliate-signup-title">Sign up and get ready to receive your monthly payouts. No application required.</h2>
              <a href="https://tryaccelerlist.tolt.io/login" className="btn btn-lg btn-primary btn-block">Sign me up</a>
            </div>
            <Testimonials />
            <Container>
              <Row>
                {videosRow1.map(item => (
                  <Col md={4} key={item.id}>
                    <VideoPlayer 
                      id={item.id}
                      title={item.title} 
                  />
                </Col>
                ))}
              </Row>
              <Row>
                {videosRow2.map(item => (
                  <Col md={4} key={item.id}>
                    <VideoPlayer 
                      id={item.id}
                      title={item.title} 
                  />
                </Col>
                ))}
              </Row>
            </Container>
          </Container>
        </div>
      </div>
    )
  }
}

export default Affiliates
