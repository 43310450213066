import axios from "axios";
import defaults from "superagent-defaults";
import { getTokenLocal } from "../helpers/auth/utils";
import { createAxiosResponseInterceptorForClient } from "../helpers/auth/token_refresh";
import { appConfig } from "./app";

const apiEndpoints = {
  homepage: {
    testimonials: "/homepage/testimonials",
    front_item_listed_count: "/homepage/front_item_listed_count",
    front_item_listed_count_ebay: "/homepage/front_item_listed_count_ebay",
    ebay_sales_graph_view: "/homepage/ebay_sales_graph_view",
    help_articles: "/homepage/help/articles",
  },
  auth: {
    login: "/auth/login",
    refresh_token: "/auth/refresh/token",
  },
  user: {
    settings: "/user/settings",
    user: "/user",
  },
  question: {
    category_all: "/question/category/all",
    question: "/question",
    all: "/question/all",
    upvote: "/question/upvote",
    reply: "/question/reply",
    pin: "/question/pin",
    move_topic: "/question/move/topic",
  },
  roadmap: {
    roadmap: "/roadmap",
    categories: "/roadmap/categories",
    search: "/roadmap/search",
    image_upload: "/roadmap/image/upload",
    upvote: "/roadmap/upvote",
    reply: "/roadmap/reply",
    roadmaps: "/roadmap/roadmaps",
  },
  admin: {
    category_all: "/admin/category/all",
    category: "/admin/category",
    topic: "/admin/topic",
    question: "/admin/question",
    reply: "/admin/reply",
    user_all: "/admin/user/all",
    roadmap: "/admin/roadmap",
    roadmap_reply: "/admin/roadmap/reply",
    product_update: "/admin/product/update",
    product_update_all: "/admin/product/update/all",
  },
  product: {
    all: "/product/all",
  },
};

const env =
  process.env.REACT_APP_NODE_ENV || appConfig.environments["production"];

const API_ROOT = appConfig.backendApiUrl[env];

const client = axios.create({ baseURL: API_ROOT });

const superagent = defaults();

const request = {
  init: () =>
    superagent.set(
      "Authorization",
      `Bearer ${getTokenLocal().toJS().access_token}`
    ),
  delete: (url) => request.init().delete(url),
  get: (url) => request.init().get(url),
  post: (url) => request.init().post(url),
  put: (url) => request.init().put(url),
};

client.defaults.headers.common["Authorization"] = `Bearer ${
  getTokenLocal().toJS().access_token
}`;

const interceptor = createAxiosResponseInterceptorForClient(client);

interceptor();

export { request, API_ROOT, client, apiEndpoints };
