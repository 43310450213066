import React, { Component } from 'react'
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import LoginForm from "./LoginForm"

class LoginModal extends Component {
  render() {
    const {
      isOpen,
      toggle,
      onLogin
    } = this.props

    return (
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Community Login</ModalHeader>
        <ModalBody>
          <p>Please login to community to vote, ask or reply question.</p>
          <LoginForm
            onLogin={onLogin}
          />
        </ModalBody>
      </Modal>
    )
  }
}

export default LoginModal