import React, { Component } from 'react';
import { Col, Container, Row } from "reactstrap";
import { hexagonPeople } from "../../assets/images";
import './style.css';

class AboutUs extends Component {
  render() {
    return (
      <div className="inner-page page-about">
        <div className="inner-page-header">
          <Container>
            <h1 className="inner-page-title">Global Support Team</h1>
            <p className="inner-page-subtitle">American roots, global reach, faster workflows.</p>
          </Container>
        </div>
        <div className="inner-page-content">
          <Container>
            <Row>
              <Col lg={6}>
                <p>Our Mission is simple: Make it easier for Amazon sellers to get their job done. We provide bulk product listing,
                  repricing and accounting all in one application. We are constantly working towards streamlining the day to day
                  processes of Amazon Sellers. It’s not just about us here at AccelerList—as we evolve we are also helping your
                  business grow and evolve along side us.
                </p>
                <br />
                <p className="lead"><a href="mailto:gethelp@accelerlist.com">gethelp@accelerlist.com</a></p>
                <p className="lead"><a href="https://accelerlist.upvoty.com" target="_blank" rel="noopener noreferrer">Suggest a Feature!</a></p>
              </Col>
            </Row>
            <img src={hexagonPeople} alt="About AccelerList" className="img-fluid page-about-image" />
          </Container>
        </div>
      </div>
    )
  }
}

export default AboutUs;