import React, { Component } from 'react';
import { Container } from "reactstrap";
import { heroImg, marketplaces_logo, speedArrows } from "../../../assets/images";
import './style.css';

class Hero extends Component {

  randomNumber = () => {
    const date = new Date();
    const generator = new Math.seedrandom(date.toDateString());
    const randomNumber = generator() * 20 + 12;
    return Math.floor(randomNumber);
  }

  render() {
    return (
      <section className="hero">
        <Container>
          <div className="hero-left">
            <h1 className="hero-title">
              <span className="highlight-yellow">Dead simple</span> Amazon product listing with free accounting and <span id="plusEbayListing"> <span className="ebay-e">e</span><span className="ebay-b">B</span><span className="ebay-a">a</span><span className="ebay-y">y</span></span> cross listing. 
              Comes with a free mobile app for product sourcing!
            </h1>
            <p className="hero-subtitle">
              Run your entire Amazon business with AccelerList and stop using multiple applications to get work done.<br/>
              <strong>Sell on eBay & Amazon, double your profits!</strong>
            </p>
            <div className="hero-cta">
              <a href="https://app.accelerlist.com/register" className="btn btn-primary btn-lg btn-icon hero-primary-btn">
                <span>Start your FREE 14 days trial now</span>
                <span className="icon icon-arrow-right icon-24"></span>
              </a>
              <div className="hero-cta-fomo">
                <img src={speedArrows} alt="" />
                <p>
                  <strong>Hurry:</strong> {this.randomNumber()} sellers signed up <strong className="text-warning">today</strong>.
                  Act now and <br/>start making $$$ on Amazon and eBay!
                </p>
              </div>
            </div>
          </div>
          <div className="hero-right">
            <div className="hero-img-wrapper">
              <div className="hero-img" style={{ backgroundImage: `url(${heroImg})` }}>
                <img src={heroImg} className="img-fluid" alt="AccelerList Listing Software" />
              </div>
            </div>
            <div className="text-center">
              <p className="mb-1">Multi-Channel Product Listing on these marketplaces</p>
              <img src={marketplaces_logo} className="img-fluid" alt="Marketplaces logo" />
            </div>
          </div>
        </Container>
      </section>
    )
  }
}

export default Hero;
