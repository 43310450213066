import { all, takeLatest, put, fork, call } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { sdMessage } from "../../helpers/utils";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* userGetData() {
	yield takeLatest(actions.USER_GET_DATA, function*(payload) {
		try {
			const res = yield call(apiCalls.get, apiEndpoints.user.user, null);
			if (res.data.error) {
				yield put(actions.userGetDataError());
			} else {
				yield put(actions.userGetDataSuccess(res));
			}
		} catch (error) {
				yield put(actions.userGetDataError());
		}
	})
}

export function* userSaveData() {
	yield takeLatest(actions.USER_SAVE_DATA, function*(payload) {
		try {
			const res = yield call(apiCalls.put, apiEndpoints.user.user, payload.data);
			if (res.data.error) {
				yield put(actions.userSaveDataError());
				sdMessage(res.data.error, "error");
			} else {
				yield put(actions.userSaveDataSuccess(res));
				sdMessage(res.data.message, "success");
			}
		} catch (error) {
				yield put(actions.userSaveDataError());
		}
	})
}

export function* userSetData() {
  yield takeLatest(actions.USER_SET_DATA, function*(payload) {
    const { data } = payload;
    yield put(actions.userSetDataSuccess(data));
  })
}

export function* userUpdateSettings() {
  yield commonSaga(
    actions.USER_UPDATE_SETTINGS,
    apiCalls.put,
    apiEndpoints.user.settings,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export default function* userSaga() {
	yield all([
		fork(userGetData),
		fork(userSaveData),
		fork(userSetData),
    fork(userUpdateSettings),
	]);
}
