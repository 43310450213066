import React, { Component } from 'react'
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import PostForm from "./PostForm"
import LoginForm from "./LoginForm"

class PostModal extends Component {
  render() {
    const {
      isOpen,
      toggle,
      isLoggedIn,
      onLogin,
      categories,
    } = this.props

    return (
      <Modal isOpen={isOpen} toggle={toggle} centered size={isLoggedIn ? 'lg' : 'sm'}>
        {isLoggedIn ?
        <React.Fragment>
          <ModalHeader toggle={toggle}>Ask a question</ModalHeader>
          <ModalBody>
            <PostForm
              placeholder="Type your question..."
              categories={categories}
              toggle={toggle}
            />
          </ModalBody>
        </React.Fragment>
        :
        <ModalBody>
          <LoginForm
            onLogin={() => onLogin()}
          />
        </ModalBody>
        }
      </Modal>
    )
  }
}

export default PostModal
