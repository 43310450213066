const faq_data = {
  "topics": [
    {
        "topic_type": "ebay",
        "description": "How does this save me time?",
        "order_id": 1,
        "id": 1,
        "name": "Overview"
    },
    {
        "topic_type": "ebay",
        "description": "Amazon Questions",
        "order_id": 2,
        "id": 2,
        "name": "Amazon Questions"
    },
    {
        "topic_type": "ebay",
        "description": "eBay Questions",
        "order_id": 3,
        "id": 3,
        "name": "eBay Questions"
    }
  ],
  "articles": [
    {
        "topic_id": 1,
        "title": "How does this save me time?",
        "id": 2,
        "answer": "Our eBay integration saves you thousands of hours listing your Amazon products to your eBay account.  If you had 1,000 products in your Amazon inventory, for example, and then attempted to cross list those items to eBay it would take you months to complete.\n\nWith our integration you are only a few clicks away from cross listing your Amazon inventory over to eBay and making more sales! "
    },
    {
        "topic_id": 2,
        "title": "Is this OK with Amazon's TOS (Terms of Service)?",
        "id": 3,
        "answer": "Of course!  We wouldn't do it if it wasn't.  Amazon has an entire program devoted to cross platform sales called MCF (Multi-Channel Fulfillment) and they are growing it rapidly each year.  MCF sales still earn Amazon money through fees and it helps thin out their warehouses so they are fully behind it."
    },
    {
        "topic_id": 1,
        "title": "How does this make me more money?",
        "id": 4,
        "answer": "With our eBay inventory integration your products get double the exposure on the two of the largest marketplaces, Amazon and eBay.  We've have sellers earning thousands of dollars more each month with increased sales on eBay and reduced exposure to LTSF (Long Term Storage Fees). "
    },
    {
        "topic_id": 2,
        "title": "Do I have to upload a picture of my product?",
        "id": 5,
        "answer": "When you cross list your Amazon product to eBay with us we automatically pull the product image for you so you don't have to spend time adding photos to the eBay cross listing.  It's one less thing you have to worry about in order to start making sales on eBay."
    },
    {
        "topic_id": 1,
        "title": "How does my eBay product get fulfilled from my Amazon FBA inventory?",
        "id": 6,
        "answer": "As soon as the eBay sale happens, we are notified. Then the order is placed on Amazon for your product via their API under the MCF program.  Amazon fulfills this product from their warehouses just like any other FBA order and then sends it to your eBay customer.  We then adjust or update the quantity on both marketplaces so they stay in sync."
    },
    {
        "topic_id": 1,
        "title": "Do I need an eBay store subscription?",
        "id": 7,
        "answer": "No.  You do not need an eBay store subscription, but their lowest subscription level for an eBay store is only $4.95 and gives you 200 free product listings on eBay so it is well worth it. Most sellers have an eBay store for all the many benefits explained here: https://pages.ebay.com/seller-center/run-your-store/why-get-an-ebay-store.html"
    },
    {
        "topic_id": 2,
        "title": "Will my dashboard only show eBay sales from cross listed items in AccelerList? ",
        "id": 8,
        "answer": "Currently, your eBay sales will show up on your AccelerList dashboard in blue.  These sales will only reflect sales from products you cross-listed to your eBay account from your Amazon inventory through AccelerList.  Some time before the end of February, 2021 you will be able to see all eBay sales onto your AccelerList dashboard."
    },
    {
        "topic_id": 2,
        "title": "What does MCF mean?",
        "id": 9,
        "answer": "MCF or Multi-Channel Fulfillment is a term used by Amazon to describe their 3rd party fulfillment of products out of their warehouses.  More info: https://sell.amazon.com/fulfillment-by-amazon/fba-multi-channel.html"
    },
    {
        "topic_id": 2,
        "title": "Does Amazon customer service handle Multi-Channel Fulfillment orders?",
        "id": 10,
        "answer": "Sellers are responsible for providing customer service for their MCF orders. This includes handling requests for replacements, refunds, and returns."
    },
    {
        "topic_id": 2,
        "title": "Can I use Multi-Channel Fulfillment to fulfill international orders?",
        "id": 11,
        "answer": "Currently, MCF only supports international fulfillment for media products such as books, music and movies.  This process is called FBA Export."
    },
    {
        "topic_id": 2,
        "title": "How are Multi-Channel Fulfillment returns processed?",
        "id": 12,
        "answer": "You can choose to return items back to Amazon fulfillment centers, or follow your existing returns process."
    },
    {
        "topic_id": 2,
        "title": "What packaging does Multi-Channel Fulfillment orders use?",
        "id": 13,
        "answer": "Amazon is now offering unbranded packaging when fulfilling MCF orders."
    },
    {
        "topic_id": 3,
        "title": "What is the Amazon shipping speed for my eBay sales?",
        "id": 14,
        "answer": "MCF ships orders at 1-day (Priority), 2-day (Expedited), and Standard ship speeds. These are the same ship speeds used by FBA to fulfill orders for Amazon.com customers. MCF also offers exports (cross-border shipments to a customer in another country), but only for books, music, videos, and DVDs."
    },
    {
        "topic_id": 3,
        "title": "Are there any limits to product listing for new sellers?",
        "id": 15,
        "answer": "eBay limits brand new sellers to 500 items a month and/or $47,500 in combined sales.  This is to protect the integrity of their marketplace and protect the accounts of new, inexperienced sellers."
    },
    {
        "topic_id": 3,
        "title": "Will zero quantity, active listings count against my monthly listing allotment?",
        "id": 16,
        "answer": "After selling a product on eBay that was cross listed over from your Amazon inventory, the listing will remain active but have a quantity of zero so that it wont be \"double sold\"."
    }
  ]
}
export default faq_data;