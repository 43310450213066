import React, { Component } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

class CardFAQ extends Component {

  render() {
    const {
      title,
      data,
    } = this.props;

    return (
      <Card className="card-faq">
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <div className="faq-grid">
            {data && data.map((item, idx) => {
              return (
                <article key={`faq-grid-${idx}`}>
                  <h4 className="question">{item.title}</h4>
                  <div className="answer">
                    <p>{item.answer}</p>
                  </div>
                </article>
              )
            })}
          </div>
        </CardBody>
      </Card>
    )
  }
}

export default CardFAQ;
