import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from "reactstrap";
import './style.css';
import { digitСonversion } from "../../../helpers/utils";
import { sealRetro } from "../../../assets/images";


class Statistic extends Component {
  render() {
    return (
      <section className="statistic">
        <Container>
          <div className="statistic-inner">
            <h2 className="section-title">Your Amazon business<br/> starts here</h2>
            <p>
              <span className="statistic-number">
                {digitСonversion(this.props.item_count, "decimal", null, 0)}
              </span>
              <span>products listed on Amazon across tens of <br/>thousands of sellers since 2016.</span>
            </p>
            <div className="statistic-top-right">
              <p>Celebrating 8 years in business</p>
              <div className="hstack gap-2 justify-content-center">
                <span className="icon icon-22 icon-star bg-warning" />
                <span className="icon icon-22 icon-star bg-warning" />
                <span className="icon icon-22 icon-star bg-warning" />
                <span className="icon icon-22 icon-star bg-warning" />
              </div>
              <img src={sealRetro} alt="" className="seal" />
            </div>
            <p className="statistic-bottom-right">
              Helping sellers just like YOU be successful on Amazon since 2016
            </p>
          </div>
        </Container>
      </section>
    )
  }
}

export default connect(
  state => ({
    item_count: state.Front.get("item_count"),
  }),
  {
  }
)(Statistic);
