import React, { Component } from 'react';

class WallOfLove extends Component {
  componentDidMount = () => {
    if (!document.getElementById("testimonialto_script")) {
      var testimonialtoScript = document.createElement("script");
      testimonialtoScript.id = "testimonialto_script"
      testimonialtoScript.type = "text/javascript"
      testimonialtoScript.src = "https://testimonial.to/js/iframeResizer.min.js"
      testimonialtoScript.async = true
      document.body.appendChild(testimonialtoScript);
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.innerHTML = 'iFrameResize({log: false, checkOrigin: false}, "#testimonialto-accelerlist-testimonials");';
    document.body.appendChild(script);
  }

  render() {
    return (
      <section>
        <div className="container-fluid">
          <div className="section-heading">
            <h2 className="section-title">Amazon and eBay sellers love AccelerList</h2>
          </div>
          <iframe
            title="Wall of Love"
            id="testimonialto-accelerlist-testimonials"
            src="https://embed.testimonial.to/w/accelerlist-testimonials?theme=light&card=base&randomize=off&loadMore=off&initialCount=10"
            frameBorder="0"
            scrolling="no"
            width="100%"
          ></iframe>
        </div>
      </section>
    )
  }
}

export default WallOfLove;
