import React, { Component } from 'react'
import { Link } from "react-router-dom"
import './style.css'

class Page404 extends Component {
  render() {
    return (
      <div className="page404">
        <svg width="300" height="207" xmlns="http://www.w3.org/2000/svg" className="mb-4">
          <g fill-rule="nonzero" fill="none">
            <path d="M20.438 188.47c-1.442 0-2.887.069-4.298.206l-.205.02c-9.361.953-16.208 6.477-15.927 12.85l.055 1.153c.036.727.074 1.511.105 2.381.017.427.526.757 1.158.757h230.251c16.236-.114 32.41-.256 48.074-.425 2.94.007 5.878-.167 8.795-.523 4.354-.595 7.375-1.768 9.238-3.593 2.318-2.263 2.391-5.113 2.25-8.332-.285-6.5-.568-13.328-.85-20.136-.25-6-.498-11.984-.747-17.713-.152-3.4-.493-6.4-3.126-8.694-2.852-2.48-8.053-3.738-15.46-3.738h-.267c-23.21.095-46.21 9.279-57.229 22.853-1.267 1.563-2.45 3.236-3.594 4.854a63.536 63.536 0 0 1-5.52 7.074 26.88 26.88 0 0 1-3.647 3.271c-.09.071-.191.147-.3.22-.248.186-.506.365-.76.524a24.851 24.851 0 0 1-4.651 2.433l-.164.063a27.367 27.367 0 0 1-3.26 1.03c-4.2 1.066-8.976 1.321-14.195.759a60.817 60.817 0 0 1-14.114-3.31c-4.832-1.757-9.355-3.948-13.73-6.067a317.89 317.89 0 0 0-5.893-2.805c-.373-.172-.74-.34-1.113-.507a97.756 97.756 0 0 0-6.719-2.745 58.626 58.626 0 0 0-16.01-3.412c-5.773-.364-10.86.322-15.117 2.033a24.293 24.293 0 0 0-3.419 1.696c-5.042 3.012-8.15 7.459-11.156 11.759-3.738 5.347-7.604 10.878-15.239 13.747-8.808 3.311-21.667 1.935-30.764-.457-2.86-.752-5.717-1.619-8.48-2.455a694.63 694.63 0 0 0-3.35-1.009 144.013 144.013 0 0 0-5.654-1.573l-.526-.135a55.37 55.37 0 0 0-.884-.215 74.569 74.569 0 0 0-5.624-1.148l-.428-.073a73.504 73.504 0 0 0-2.45-.33l-.315-.036a44.003 44.003 0 0 0-4.74-.253Z" fill="#F2F2F2"/>
            <path d="M47.296 205.806a.477.477 0 0 1 .475-.479h204.437a.49.49 0 0 1 .336.141.481.481 0 0 1 0 .673c-.09.09-.21.14-.336.14H47.771a.472.472 0 0 1-.336-.14.472.472 0 0 1-.14-.335Z" fill="#CACACA"/>
            <path d="M208.493 17.235a17.157 17.157 0 0 1-5.292 12.418 260.62 260.62 0 0 0-27.98-18.777 17.24 17.24 0 0 1 8.383-9.09 17.25 17.25 0 0 1 21.09 4.656 17.235 17.235 0 0 1 3.799 10.793Z" fill="#FFC165"/>
            <path d="m222.478 52.313-.015.18c-11.342-.78-22.682-1.562-34.022-2.346-8.192-.565-16.67-1.205-23.908-5.082-2.749-1.47-5.425-3.431-8.542-3.567-3.866-.173-7.239 2.53-9.891 5.35-15.677 16.69-20.216 42.21-37.803 56.87a558.16 558.16 0 0 1 39.606-79.01c2.82-4.666 6.165-9.647 11.401-11.17 4.996-1.453 10.108.67 14.707 3.393a76.649 76.649 0 0 1 2.877 1.806A507.754 507.754 0 0 1 197.8 33.18a510.119 510.119 0 0 1 24.677 19.133Z" fill="#F2F2F2"/>
            <path d="M259.16 87.712a268.608 268.608 0 0 0-41.966-46.559 275.474 275.474 0 0 0-13.993-11.5 260.62 260.62 0 0 0-27.98-18.777 247.122 247.122 0 0 0-9.679-5.33c-2.536-1.317-5.236-2.523-8.16-2.335-2.047.175-4.001.926-5.637 2.167C148.15 8 145.77 12.098 143.812 16c-1.714 3.424-3.313 6.916-4.943 10.388a297.336 297.336 0 0 1-2.103 4.417 744.536 744.536 0 0 1-18.088 34.798 747.654 747.654 0 0 1-23.73 39.908 751.567 751.567 0 0 1-39.27 55.833c-1.71 2.206-2.415 2.719-4.15 4.91-.4.512-1.113-.209-.717-.717 14.467-18.276 27.063-35.544 39.762-55.093a748.309 748.309 0 0 0 27.147-45.202 733.204 733.204 0 0 0 8.036-14.904 757.766 757.766 0 0 0 15.38-31.042c3.241-6.94 7.96-17.259 17.075-17.119 2.68.04 5.168 1.126 7.516 2.327 2.391 1.23 4.739 2.535 7.083 3.86.937.529 1.87 1.065 2.8 1.61a259.183 259.183 0 0 1 10.881 6.683 270.877 270.877 0 0 1 17.411 12.286 269.808 269.808 0 0 1 51.624 52.077c1.535 2.038 3.038 4.099 4.508 6.183.377.532-.5 1.037-.873.508ZM218.096 36.056c.53-.59 1.058-1.18 1.59-1.77 4.204-4.646 8.713-9.239 14.485-11.902a21.029 21.029 0 0 1 8.805-2.04c3.151.01 6.23.836 9.142 1.992a52.555 52.555 0 0 1 4.048 1.838c1.515.762 3.002 1.575 4.483 2.394a197.57 197.57 0 0 1 23.888 15.561 196.112 196.112 0 0 1 14.552 12.23c.48.442 1.194-.273.716-.715a191.412 191.412 0 0 0-6.926-6.12 197.818 197.818 0 0 0-25.88-18.471 196.87 196.87 0 0 0-8.435-4.768 74.18 74.18 0 0 0-2.61-1.332 41.678 41.678 0 0 0-6.208-2.525 22.092 22.092 0 0 0-9.375-.92 24.43 24.43 0 0 0-8.65 2.99c-5.665 3.166-10.062 8.062-14.34 12.84-.43.486.28 1.204.715.718Z" fill="#E4E4E4"/>
            <path d="m101.976 84.206-13.58-4.965-6.743-2.465c-2.196-.803-4.387-1.717-6.657-2.291a10.506 10.506 0 0 0-6.025.076 13.887 13.887 0 0 0-4.7 2.912 38.106 38.106 0 0 0-3.854 4.115c-1.468 1.763-2.915 3.545-4.367 5.32A2658.988 2658.988 0 0 0 .989 156.554c-.39.51-1.27.007-.874-.51a2661.296 2661.296 0 0 1 58.062-73.319c1.28-1.56 2.547-3.136 3.95-4.588 2.579-2.665 5.8-5.02 9.655-5.084 2.266-.038 4.46.681 6.566 1.443 2.27.82 4.535 1.657 6.803 2.486l13.675 5 3.419 1.25c.607.221.344 1.2-.269.975Z" fill="#E4E4E4"/>
            <path d="M235.96 28.23c6.9-3.545 15.738-2.85 22.08 1.579a315.14 315.14 0 0 0-35.832 8.358c4.888-2.863 8.711-7.348 13.751-9.937Z" fill="#F2F2F2"/>
            <path d="m258.107 29.796 1.33 1.08c-.45-.378-.918-.732-1.398-1.067l.068-.013ZM64.783 84.255c1.336-1.626 2.782-3.33 4.781-3.99l1.867.074a211.686 211.686 0 0 1-56.4 64.448l49.752-60.532Z" fill="#F2F2F2"/>
            <path d="m204.803 175.77-11.305-4.985V166.5l4.03-.023a2.996 2.996 0 0 0 2.821-2.045l2.83-8.487a2.992 2.992 0 0 0-.41-2.694 2.99 2.99 0 0 0-2.428-1.242h-6.843v-3.988h8.59a2.992 2.992 0 0 0 2.917-2.321l1.948-8.475a2.986 2.986 0 0 0-1.616-3.364 2.991 2.991 0 0 0-1.3-.297h-10.539v-.997a1.997 1.997 0 0 0-1.995-1.994 1.993 1.993 0 0 0-1.995 1.994v.997h-70.653v-.997a1.994 1.994 0 0 0-3.99 0v.997h-8.377a2.993 2.993 0 0 0-2.916 3.661l1.95 8.475a2.991 2.991 0 0 0 2.915 2.32h6.429v3.988h-4.681a2.993 2.993 0 0 0-2.849 3.909l2.89 8.96a2.993 2.993 0 0 0 2.864 2.074l1.776-.01v3.844l-9.144 4.985a2.996 2.996 0 0 0-2.764 1.846 2.99 2.99 0 0 0 2.764 4.136h9.144v21.935a1.996 1.996 0 0 0 3.99 0v-21.935h70.652v21.935a1.993 1.993 0 0 0 1.995 1.993c.529 0 1.037-.21 1.412-.583.373-.375.583-.881.583-1.41v-21.935h11.305a2.992 2.992 0 1 0 0-5.982Zm-85.947-27.75h70.652v3.988h-70.653v-3.987Zm0 22.765v-3.866l70.652-.397v4.263h-70.653Z" fill="#7AD6A1"/>
            <path d="m36.205 167.31 3.199-10.414a35.165 35.165 0 0 0-14.056-9.413c5.258 8.707.163 20.065 2.286 30.012a21.138 21.138 0 0 0 11.329 14.407l4.854 6.012a35.409 35.409 0 0 0 2.605-29.547 34.208 34.208 0 0 0-3.964-7.616c-2.884 3.39-6.253 6.558-6.253 6.558Z" fill="#F2F2F2"/>
            <path d="m18.615 168.64-1.783-10.746a35.176 35.176 0 0 0-16.78-2.16c8.591 5.45 9.099 17.887 15.437 25.843a21.148 21.148 0 0 0 16.568 7.845l7.027 3.217a35.415 35.415 0 0 0-10.852-27.608 34.23 34.23 0 0 0-6.946-5.049c-1.07 4.32-2.67 8.658-2.67 8.658ZM271.192 123.223l-6.997-8.351a35.157 35.157 0 0 1 9.289-14.134c-1.457 10.065 7.655 18.55 9.568 28.539.572 3.12.435 6.328-.401 9.388a21.174 21.174 0 0 1-4.433 8.287l-2.136 7.426a35.43 35.43 0 0 1-13.891-26.21c-.13-2.87.1-5.745.69-8.557 3.977 2.002 8.312 3.612 8.312 3.612Z" fill="#F2F2F2"/>
            <path d="m287.916 117.614-2.537-10.594A35.171 35.171 0 0 1 300 98.51c-5.796 8.359-1.427 20.015-4.172 29.808a21.144 21.144 0 0 1-12.212 13.666l-5.224 5.694a35.391 35.391 0 0 1-3.624-14.745 35.433 35.433 0 0 1 2.884-14.907 34.242 34.242 0 0 1 4.436-7.35c2.666 3.563 5.828 6.938 5.828 6.938Z" fill="#F2F2F2"/>
            <path d="M154.877 158.82a2.4 2.4 0 0 1-1.697-4.095 2.4 2.4 0 1 1 1.697 4.095Zm0-4.384a1.987 1.987 0 0 0-1.404 3.39 1.987 1.987 0 0 0 3.39-1.404 1.987 1.987 0 0 0-1.986-1.986Z" fill="#FFF"/>
          </g>
        </svg>
        <h1>Page Not Found</h1>
        <p>
          The page you are looking for was moved, removed, renamed or might be never have existed.
        </p>
        <div className="hstack gap-3 justify-content-center">
          <Link to="/" className="btn btn-primary">Return to home</Link>
          <Link to="/community/questions" className="btn btn-secondary">Visit AccelerList Community</Link>
        </div>
      </div>
    )
  }
}

export default Page404