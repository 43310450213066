import moment from "moment"
import React, { Component, useState } from 'react'
import { connect } from "react-redux";
import { Helmet } from "react-helmet"
import { Alert, Badge, Button, Input, Popover, PopoverBody, UncontrolledTooltip } from "reactstrap"
import ReplyForm from "./ReplyForm"
import { Link, useParams } from "react-router-dom"
import communityActions from "../../redux/community/actions";
import draftToHtml from 'draftjs-to-html';
import LoginModal from "./LoginModal";
import Loading from "../../shared/components/Loading";
import Dialog from "../../shared/components/Dialog";
import { protectSpecialUsername, usernameToColor } from '../../helpers/utils';
import { EditorState, convertFromRaw } from "draft-js";

const {
  communityGetQuestion,
  communityQuestionUpvote,
  communityQuestionReply,
  adminUpdateQuestion,
  adminUpdateReply,
  adminDeleteReply,
  adminDeleteQuestion,
} = communityActions;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class QuestionDetails extends Component {
  state = {
    replyEditorActive: false,
    showLoginModal: false,
    deleteQuestionId: null,
    deleteReplyid: null
  }

  componentDidMount = () => {
    this.props.communityGetQuestion({
      slug: this.props.params?.slug,
      user: this.props.userData?.id,
    })
  }

  render() {
    const { question, userData, questions_working } = this.props;
    const { deleteQuestionId, deleteReplyid } = this.state

    let metaDescription = ''
    if(question?.question) {
      const editorState = EditorState.createWithContent(convertFromRaw(question.question))
      metaDescription = editorState.getCurrentContent().getPlainText('\u0001')
    }

    return (
      <div className="question-details">
        <Helmet>
          <title>{`${question?.title} | Community | AccelerList`}</title>
          <meta name="description" content={metaDescription?.substring(0,299)} />
        </Helmet>
        <div className="inner-page-header">
          <Link to="/community/questions">&larr; Back to questions</Link>
        </div>
        <div className="inner-page-content">
          {questions_working ?
          <Loading className="py-4" />
          :
          <React.Fragment>
          {question.is_duplicate &&
          <Alert color="info">
            This question has been asked before, please see <a href={question.duplicate_url}>{question.duplicate_url}</a>
          </Alert>
          }
          <Post
            avatar_url={question?.author?.profile_image || null}
            name={question?.author?.username ? question?.author?.username : `user${question?.author?.id}`}
            nameInitial={question?.author?.username.charAt(0)}
            timestamp={moment(question?.created_at)}
            isUpvoted={question?.upvoted ? true : false}
            // isReply always false if this a question post
            title={question?.title}
            description={question?.question}
            upvote={this.props.communityQuestionUpvote}
            userData={userData}
            upvoteCount={question?.upvotes ? question?.upvotes : 0}
            questionId={question?.id}
            questionUserId={question?.id_user}
            onConfirmDelete={() => this.setState({ deleteQuestionId: question?.id })}
            isDuplicate={question.is_duplicate}
            adminUpdateQuestion={this.props.adminUpdateQuestion}
            questions_working={questions_working}
            isResolved={question?.resolved}
          />
          <ul className="question-reply-list">
            {question?.replies?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            .map(reply => {
              return (
                <li key={`reply-${reply?.id}`}>
                  <Post
                    avatar_url={reply?.author?.profile_image || null}
                    name={reply?.author?.username ? reply?.author?.username : `user${reply?.author?.id}`}
                    nameInitial={reply?.author?.username.charAt(0)}
                    isAuthor={question?.id_user === reply?.id_user}
                    timestamp={moment(reply?.created_at)}
                    isReply={true}
                    description={reply?.reply}
                    userData={userData}
                    onConfirmDelete={() => this.setState({ deleteReplyid: reply?.id })}
                    questionUserId={question?.id_user}
                    isSolution={reply?.is_solution}
                    adminUpdateReply={this.props.adminUpdateReply}
                    questions_working={questions_working}
                    replyId={reply.id}
                  />
                </li>
              )
            })}
          </ul>
          {!question.is_duplicate &&
          <div className="reply-question">
            <div
              className="post-info-avatar"
              style={userData?.profile_image && { backgroundImage: `url(${userData?.profile_image})`}}
            >
              {userData?.profile_image ?
              <img src={userData?.profile_image} alt="" />
              :
              <span
                className={`avatar-default`}
                style={usernameToColor(userData?.username)}
                title={protectSpecialUsername(userData?.username)}
              >{userData?.username ? userData.username.charAt(0) : '?'}</span>
              }
            </div>
            {!this.state.replyEditorActive ?
              <Button
                color="primary"
                onClick={() => {
                  if(userData.username) this.setState({ replyEditorActive: true })
                  else this.setState({ showLoginModal: true })
                }}
              >Reply to question</Button>
              :
              <ReplyForm
                placeholder="Type your reply..."
                closeReply={() => this.setState({ replyEditorActive: false })}
              />
            }
            <LoginModal
              isOpen={this.state.showLoginModal}
              toggle={() => this.setState({ showLoginModal: !this.state.showLoginModal })}
            />
          </div>
          }
          </React.Fragment>
          }
        </div>
        {deleteQuestionId &&
        <Dialog
          isOpen={true}
          close={() => this.setState({ deleteQuestionId: null })}
          type="delete"
          title="Delete question"
          message={<span>Question and all replies will be deleted.
          <br/>Are you sure want to delete this question?</span>}
          deleteButtonText="Delete question"
          onDelete={() => {
            this.props.adminDeleteQuestion({id: deleteQuestionId})
            this.setState({ deleteQuestionId: null })
          }}
        />
        }
        {deleteReplyid &&
        <Dialog
          isOpen={true}
          close={() => this.setState({ deleteReplyid: null })}
          type="delete"
          title="Delete reply"
          message="Are you sure want to delete this reply?"
          deleteButtonText="Delete reply"
          onDelete={() => {
            this.props.adminDeleteReply({id: deleteReplyid})
            this.setState({ deleteReplyid: null })}
          }
        />
        }
      </div>
    )
  }
}

const Post = ({ avatar_url, name, timestamp, title, description, isAuthor,
                isReply = false, upvote, userData, upvoteCount, questionId,
                questionUserId, onConfirmDelete, isSolution = false,
                isDuplicate, questions_working, adminUpdateQuestion,
                adminUpdateReply, replyId, isUpvoted, isResolved = false, nameInitial
               }) => {

const [showPopoverURL, setShowPopoverURL] = useState(false)
const togglePopoverURL = () => setShowPopoverURL((prevState) => !prevState);
const [duplicateURL, setDuplicateURL] = useState("")

return (
  <div className={`post ${isDuplicate ? 'post-duplicate' : ''}`}>
    <div className="hstack gap-2">
    {!isReply ?
      userData.username && !isDuplicate
        ? <div
            className="post-vote"
            id="upvote"
            role="button"
            onClick={() => upvote({
              id_question: questionId,
              id_user: userData?.id
            })}
            style={isUpvoted ? { pointerEvents: "none" } : {}}
          >
            <span className="post-vote-count">{upvoteCount}</span>
            <span
              className={`icon icon-18 icon-caret-up icon-vote${isUpvoted ? " bg-danger" : ""}`}
              title="Upvote question"
              role="button"
            />
            {!isUpvoted &&
              <UncontrolledTooltip target="upvote" placement="left">Upvote this question</UncontrolledTooltip>
            }
          </div>
        : <div className="post-vote">{upvoteCount}</div>
      : null
      }
      <div className="post-info">
        <div
          className="post-info-avatar"
          style={avatar_url && { backgroundImage: `url(${avatar_url})`}}
        >
          {avatar_url ?
          <img src={avatar_url} alt={name} />
          :
          <span
            className={`avatar-default`}
            style={usernameToColor(name)}
          >{nameInitial}</span>
          }
        </div>
        <span className="post-info-name">
          {protectSpecialUsername(name)}
        </span>
        {isAuthor &&
        <span className="post-info-badge">Author</span>
        }
        <span className="post-info-timestamp">{moment(timestamp).from(new Date())}</span>
        {isReply && (userData.is_admin || (questionUserId === userData.id)) && !isSolution?
        <Button
          color="link"
          onClick={() => adminUpdateReply({
            id: replyId,
            update_data: {
              is_solution: true,
            }
          })}
          disabled={questions_working}
        >Mark as solution</Button>
        : null
        }
        {isReply && isSolution &&
        <Badge color="success">SOLUTION</Badge>
        }
        {userData.is_admin && !isReply && !isDuplicate ?
        <div>
          <Button color="link" id="markDuplicate">Mark as duplicate</Button>
          <Popover isOpen={showPopoverURL} toggle={togglePopoverURL} target="markDuplicate" placement="bottom">
            <PopoverBody>
              <Input
                type="url"
                placeholder="Provide URL to existing similar question"
                style={{ minWidth: '25rem' }}
                bsSize="sm"
                onChange={(e) => setDuplicateURL(e.target.value)}
                value={duplicateURL}
              />
              <Button
                size="sm"
                color="primary"
                className="mt-2"
                onClick={() => {
                  adminUpdateQuestion({
                    id: questionId,
                    update_data: {
                      is_duplicate: true,
                      duplicate_url: duplicateURL,
                    }
                  })
                  togglePopoverURL();
                }}
                disabled={!duplicateURL || questions_working}
              >Proceed</Button>
            </PopoverBody>
          </Popover>
        </div>
        : null
        }
        {userData.is_admin && !isReply && !isResolved ?
        <Button
          color="link"
          onClick={() => {
            adminUpdateQuestion({
              id: questionId,
              update_data: {
                resolved: true,
              }
            })
          }}
          disabled={questions_working}
        >Mark as resolved</Button>
        : null
        }
        {isResolved &&
        <Badge color="success">RESOLVED</Badge>
        }
        {userData.is_admin &&
        <span
          className="icon icon-trash bg-danger"
          role="button"
          title="Delete"
          onClick={onConfirmDelete}
        />
        }
      </div>
    </div>
    <div className="post-content">
      {title &&
      <h1 className="post-title">{title}</h1>
      }
      <div
        className="post-description"
        dangerouslySetInnerHTML={{__html: draftToHtml(description)}}
      />
    </div>
  </div>
)}

export default withParams(connect(
  state => ({
    userData: state.User.get("userData"),
    question: state.Community.get("question"),
    questions_working: state.Community.get("questions_working"),
  }),
  {
    communityGetQuestion,
    communityQuestionUpvote,
    communityQuestionReply,
    adminUpdateQuestion,
    adminUpdateReply,
    adminDeleteReply,
    adminDeleteQuestion,
  }
)(QuestionDetails));
