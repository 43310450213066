import React, { Component } from "react"
import { connect } from "react-redux";
import {
  Button, Card, CardBody, CardHeader, Col, Input, InputGroup,
  Popover, PopoverBody, PopoverHeader, Row
} from "reactstrap"
import Dialog from "../../../shared/components/Dialog"
import Select from "react-select"
import adminActions from "../../../redux/admin/actions";

const {
  adminGetCategories,
  adminAddCategory,
  adminDeleteCategory,
  adminUpdateCategory,
  adminAddTopic,
  adminUpdateTopic,
  adminDeleteTopic,
} = adminActions;

class AdminCategoriesTopics extends Component {
  state = {
    deleteTopicId: null,
    editTopicId: null,
    editTopicName: null,
    moveTopicId: null,
    editCategoryId: null,
    editCategoryName: null,
    deleteCategoryId: null,
    category_name: "",
    topic_name: {},
  }

  componentDidMount = () => {
    this.props.adminGetCategories();
  }

  updateTopicName = (id, value) => {
    var topic_name = {...this.state.topic_name}
    topic_name[id] = value;
    this.setState({topic_name: topic_name})
  }

  createMoveOptions = (id) => {
    const { categories, } = this.props;
    if(!categories){ return []; }
    const options = Object.keys(categories).map(key => {
      return {
        label: categories[key].name,
        value: key,
      }
    })
    return options.filter(item => item.value !== id)
  }

  render() {
    const {
      deleteTopicId,
      editTopicId,
      moveTopicId,
      deleteCategoryId,
      editCategoryId,
    } = this.state
    const { categories, categories_working } = this.props;

    console.log("AdminCategories:", categories);

    return (
      <div className="admin admin-categories-topics">
        <div className="mb-4" style={{ maxWidth: '30rem' }}>
          <InputGroup>
            <Input
              placeholder="New category name"
              value={this.state.category_name}
              onChange={(e) => this.setState({category_name: e.target.value})}
            />
            <Button
              color="primary"
              onClick={() => {
                this.props.adminAddCategory({name: this.state.category_name});
                this.setState({category_name: ""});
              }}
              disabled={!this.state.category_name || categories_working}
            >Add category</Button>
          </InputGroup>
        </div>
        <Row className="admin-category-group">
          {Object.keys(categories).map(category_id => (
            <Col md={4} key={`category-col-${category_id}`}>
              <Card className="admin-category-item mb-4" key={`category-item-${categories[category_id].id}`}>
                <CardHeader className="h6 hstack justify-content-between gap-2">
                  {(editCategoryId === categories[category_id].id && categories[category_id].id !== 1) ?
                  <InputGroup size="sm">
                    <Input
                      autoFocus
                      value={this.state.editCategoryName === null
                        ? categories[category_id].name
                        : this.state.editCategoryName
                            ? this.state.editCategoryName
                            : ""
                      }
                      onChange={(e) => this.setState({editCategoryName: e.target.value})}
                    />
                    <Button
                      color="primary"
                      onClick={() => {
                        // do some update then back to read mode
                        this.props.adminUpdateCategory({
                          id: editCategoryId,
                          update_data: {
                            name: this.state.editCategoryName,
                          }
                        })
                        this.setState({
                          editCategoryId: null,
                          editCategoryName: null,
                        })
                      }}
                      disabled={!this.state.editCategoryName || categories_working}
                    >&#x2714;</Button>
                    <Button
                      onClick={() => this.setState({
                        editCategoryId: null,
                        editCategoryName: null,
                      })}
                    >&#x2715;</Button>
                  </InputGroup>
                  :
                  <span>{categories[category_id].name}</span>
                  }
                  {categories[category_id].id !== 1 &&
                  <div className="hstack gap-2">
                    <span
                      className="icon icon-edit"
                      role="button"
                      disabled={categories_working}
                      onClick={() => this.setState({ editCategoryId: categories[category_id].id })}
                    />
                    <span
                      className="icon icon-trash bg-danger"
                      role="button"
                      disabled={categories_working}
                      onClick={() => this.setState({ deleteCategoryId: categories[category_id].id })}
                    />
                  </div>
                  }
                </CardHeader>
                <CardBody>
                  {Object.keys(categories[category_id]?.topics).map(topic_id => (
                    <div
                      className={`admin-topic-item ${categories[category_id].topics[topic_id].name === 'Uncategorized' ? 'bg-light' : ''}`}
                      key={`topic-item-${categories[category_id].topics[topic_id].id}`}
                    >
                      {(editTopicId === categories[category_id].topics[topic_id].id && categories[category_id].topics[topic_id].id !== 1) ?
                      <InputGroup size="sm">
                        <Input
                          autoFocus
                          value={this.state.editTopicName === null
                            ? categories[category_id].topics[topic_id].name
                            : this.state.editTopicName
                                ? this.state.TopicName
                                : ""
                          }
                          onChange={(e) => this.setState({editTopicName: e.target.value})}
                        />
                        <Button
                          color="primary"
                          onClick={() => {
                            // do some update then back to read mode
                            this.props.adminUpdateTopic({
                              id: editTopicId,
                              update_data: {
                                name: this.state.editTopicName,
                              }
                            })
                            this.setState({
                              editTopicId: null,
                              editTopicName: null,
                            })
                          }}
                          disabled={!this.state.editTopicName || categories_working}
                        >&#x2714;</Button>
                        <Button onClick={() => this.setState({ editTopicId: null })}>&#x2715;</Button>
                      </InputGroup>
                      :
                      <span>{categories[category_id].topics[topic_id].name}</span>
                      }

                      {/* Uncategorized topic can't be moved, edited and deleted */}
                      {categories[category_id].topics[topic_id].id !== 1 &&
                      <div className="hstack gap-2">
                        <div>
                          <span
                            id={`move-${categories[category_id].topics[topic_id].id}`}
                            role="button"
                            disabled={categories_working}
                            onClick={() => this.setState({ moveTopicId: categories[category_id].topics[topic_id].id })}
                          >&#x21aa;</span>
                          <Popover
                            isOpen={moveTopicId === categories[category_id].topics[topic_id].id}
                            toggle={() => this.setState({ moveTopicId: null })}
                            target={`move-${categories[category_id].topics[topic_id].id}`}
                            trigger="focus"
                            placement="bottom"
                            disabled={categories_working}
                          >
                            <PopoverHeader className="hstack justify-content-between">
                              <span>Move to</span>
                              <span
                                role="button"
                                disabled={categories_working}
                                onClick={() => this.setState({ moveTopicId: null })}
                              >&#x2715;</span>
                            </PopoverHeader>
                            <PopoverBody>
                              <Select
                                options={this.createMoveOptions(category_id)}
                                onChange={selected => {
                                  this.props.adminUpdateTopic({
                                    id: topic_id,
                                    update_data: {
                                      id_category: selected.value,
                                    }
                                  })
                                  this.setState({ moveTopicId: null })
                                }}
                              />
                            </PopoverBody>
                          </Popover>
                        </div>
                        <span
                          className="icon icon-edit"
                          role="button"
                          disabled={categories_working}
                          onClick={() => this.setState({
                            editTopicId: categories[category_id].topics[topic_id].id,
                            editTopicName: null,
                          })}
                        />
                        <span
                          className="icon icon-trash bg-danger"
                          role="button"
                          disabled={categories_working}
                          onClick={() => this.setState({ deleteTopicId: categories[category_id].topics[topic_id].id })}
                        />
                      </div>
                      }
                    </div>
                  ))}
                  <InputGroup>
                    <Input
                      placeholder="New topic"
                      value={this.state.topic_name[category_id] || ""}
                      onChange={(e) => this.updateTopicName(category_id, e.target.value)}
                    />
                      <Button
                        color="primary"
                        onClick={() => {
                          this.props.adminAddTopic({
                            id_category: category_id,
                            name: this.state.topic_name[category_id],
                          })
                          this.updateTopicName(category_id, "");
                        }}
                        disabled={!this.state.topic_name[category_id] || categories_working}
                      >Add topic</Button>
                  </InputGroup>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        {deleteTopicId &&
        <Dialog
          isOpen={true}
          close={() => this.setState({ deleteTopicId: null })}
          type="delete"
          title="Delete topic"
          message={<span>All questions under this topic will be moved to <strong>Uncategorized</strong> topic. <br/>Are you sure want to delete this topic?</span>}
          deleteButtonText="Delete topic"
          onDelete={() => {
            this.props.adminDeleteTopic({"id": deleteTopicId});
            this.setState({ deleteTopicId: null })}
          }
        />
        }
        {deleteCategoryId &&
        <Dialog
          isOpen={true}
          close={() => this.setState({ deleteCategoryId: null })}
          type="delete"
          title="Delete category"
          message={<span>All topics (and its questions) under this category will be moved to <strong>Others</strong> category.
          <br/>Are you sure want to delete this category?</span>}
          deleteButtonText="Delete category"
          onDelete={() => {
            this.props.adminDeleteCategory({"id": deleteCategoryId});
            this.setState({ deleteCategoryId: null })}
          }
        />
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    categories: state.Admin.get("categories"),
    categories_working: state.Admin.get("categories_working"),
  }),
  {
    adminGetCategories,
    adminAddCategory,
    adminDeleteCategory,
    adminUpdateCategory,
    adminAddTopic,
    adminUpdateTopic,
    adminDeleteTopic,
  }
)(AdminCategoriesTopics);
