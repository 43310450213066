import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { logo, logoMark } from "../../assets/images";
import './style.css';

class Press extends Component {
  render() {
    return (
      <div className="inner-page page-press">
        <div className="inner-page-header">
          <Container>
            <h1 className="inner-page-title">Brand Asserts & Press</h1>
            <p className="inner-page-subtitle">
              Download our logos and digital assets for your media needs. Please follow our brand guidelines at all times across all channels. 
            </p>
          </Container>
        </div>
        <div className="inner-page-content">
          <Container>
            <section className="text-center">
              <a
                href="https://shareholders-data.s3-us-west-2.amazonaws.com/accelerlist-logo-files-and-brand-guidelines.zip"
                className="btn btn-primary"
              >Download Kit</a>
            </section>
            <section>
              <h2 className="section-title">Logo</h2>
              <Row>
                <Col md={6} className="mb-4 mb-md-0">
                  <h3 className="h5">Primary Logo</h3>
                  <p>Primary version of our logo. Should be used whenever possible to officially represent the company.</p>
                  <div className="download-logo">
                    <div className="logo-wrapper"><img src={logo} alt="" /></div>
                  </div>
                </Col>
                <Col md={6}>
                  <h3 className="h5">Logo Mark</h3>
                  <p>Responsive version of our original logo. Should be used for social media profile photos, or as a standalone mark in some cases.</p>
                  <div className="download-logo">
                    <div className="logo-wrapper"><img src={logoMark} alt="" /></div>
                  </div>
                </Col>
              </Row>
            </section>
            <section>
              <h2 className="section-title">Brand Colors</h2>
              <div className="brand-colors">
                <div className="color-item" style={{ background: "#00C854" }}>
                  <div className="color-code">
                    <span>Hex: #00C854</span>
                    <span>RGB: 0, 200, 84</span>
                  </div>
                </div>
                <div className="color-item" style={{ background: "#002740" }}>
                  <div className="color-code">
                    <span>Hex: #002740</span>
                    <span>RGB: 0, 39, 64</span>
                  </div>
                </div>
                <div className="color-item" style={{ background: "#E2F9EB" }}>
                  <div className="color-code" style={{ color: "#002740" }}>
                    <span>Hex: #E2F9EB</span>
                    <span>RGB: 226, 249, 235</span>
                  </div>
                </div>
                <div className="color-item" style={{ background: "#3D5FC0" }}>
                  <div className="color-code">
                    <span>Hex: #3D5FC0</span>
                    <span>RGB: 41, 98, 255</span>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <h2 className="section-title">How to write our name: AccelerList</h2>
              <Row>
                <Col md={6} className="mb-4 mb-md-0">
                  <Card>
                    <CardBody>
                      <h3 className="h6">Please <span className="text-success">DO</span></h3>
                      <ol className="list-rules">
                        <li>Capitalize the "A" and "L" (<strong className="text-success">A</strong>cceler<strong className="text-success">L</strong>ist)</li>
                        <li>Use one word</li>
                      </ol>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <CardBody>
                      <h3 className="h6">Please <span className="text-danger">DON'T</span></h3>
                      <ol className="list-rules">
                        <li>Use one capital</li>
                        <li>Separate into two words</li>
                      </ol>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </section>
          </Container>
        </div>
      </div>
    )
  }
}

export default Press;