import { Map } from "immutable";

const initState = new Map({
  testimonials: null,
  testimonials_loading: false,
  item_count: 48518084,
  ebay_count: {
    count: 162958,
    sales: 373311,
  },
  ebay_sales_graph_data: null,
  help_articles: [],
  help_articles_working: false,
});

export default function frontReducer(state = initState, action) {
	switch (action.type) {

		case "FETCH_HELP_ARTICLES":
			return state
        .set("help_articles_working", true)
		case "FETCH_HELP_ARTICLES_SUCCESS":
			return state
        .set("help_articles", [...action.data.data])
        .set("help_articles_working", false)
		case "FETCH_HELP_ARTICLES_FAILED":
			return state
        .set("ebay_sales_graph_data", null)
        .set("help_articles_working", false)

		case "FETCH_EBAY_SALES_GRAPH":
			return state
        .set("ebay_sales_graph_data", null)
		case "FETCH_EBAY_SALES_GRAPH_SUCCESS":
			return state
        .set("ebay_sales_graph_data", action.data.data)
		case "FETCH_EBAY_SALES_GRAPH_FAILED":
			return state
        .set("ebay_sales_graph_data", null)

		case "FETCH_INVENTORY_ITEMS_COUNT_EBAY":
			return state
        .set("ebay_count", {count: 162958, sales: 373311})
		case "FETCH_INVENTORY_ITEMS_COUNT_EBAY_SUCCESS":
			return state
        .set("ebay_count", action.data.data)
		case "FETCH_INVENTORY_ITEMS_COUNT_EBAY_FAILED":
			return state
        .set("ebay_count", {count: 162958, sales: 373311})

		case "FETCH_INVENTORY_ITEMS_COUNT":
			return state
        .set("item_count", 48518084)
		case "FETCH_INVENTORY_ITEMS_COUNT_SUCCESS":
			return state
        .set("item_count", action.data.data.count)
		case "FETCH_INVENTORY_ITEMS_COUNT_FAILED":
			return state
        .set("item_count", 48518084)

		case "FRONT_GET_TESTIMONIALS":
			return state
        .set("testimonials", null)
				.set("testimonials_working", true)
		case "FRONT_GET_TESTIMONIALS_SUCCESS":
			return state
        .set("testimonials", action.data.data)
				.set("testimonials_working", false)
		case "FRONT_GET_TESTIMONIALS_FAILED":
			return state
        .set("testimonials", null)
				.set("testimonials_working", false)

		default:
			return state;
  }
}
