import React, { Component } from 'react'
import { connect } from "react-redux";
import { Badge, Table } from "reactstrap"
import adminActions from "../../../redux/admin/actions";
import moment from "moment";

const {
  adminProductUpdateAll,
} = adminActions;

class AdminPostsManage extends Component {

  componentDidMount = () => {
    this.props.adminProductUpdateAll();
  }

  render() {
    const { onEdit, onDelete, product_updates } = this.props

    return (
      <div className="admin admin-posts-manage">
         <Table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Status</th>
                <th>Date</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              {product_updates?.items?.map(item => (
                <tr key={item.id}>
                  <td>{item.title}</td>
                  <td>{item.published ? <Badge color="success">Published</Badge> : <Badge color="dark">Draft</Badge>}</td>
                  <td>{moment(item.created_at).from(new Date())}</td>
                  <td className="text-end">
                    <span
                      className="icon icon-edit"
                      role="button"
                      onClick={() => onEdit(item.id)}
                    />
                    <span
                      className="icon icon-trash ms-2 bg-danger"
                      role="button"
                      onClick={() => onDelete(item.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
      </div>
    )
  }
}

export default connect(
  state => ({
    product_updates: state.Admin.get("product_updates"),
    product_updates_working: state.Admin.get("product_updates_working"),
  }),
  {
    adminProductUpdateAll,
  }
)(AdminPostsManage);
