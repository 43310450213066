import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button } from "reactstrap"
import AdminPostsManage from "./AdminPostsManage.js";
import AdminPostForm from "./AdminPostForm.js";
import Dialog from "../../../shared/components/Dialog/index.js";
import adminActions from "../../../redux/admin/actions";

const {
  adminProductUpdateDelete,
  adminProductUpdateGet,
} = adminActions;

class AdminProductUpdates extends Component {
  state = {
    mode: 'post-list',
    editId: null,
    deleteId: null
  }

  render() {
    const { mode, deleteId } = this.state

    return (
      <div className="admin admin-product-updates">
        {mode === 'post-list' &&
        <React.Fragment>
        <div className="hstack justify-content-between mb-4">
          <h3 className="h5 mb-0">Manage Posts</h3>
          <Button
            color="primary"
            onClick={() => this.setState({ mode: 'post-create' })}
          >Create new post</Button>
        </div>
        <AdminPostsManage
          onEdit={id => {
            this.setState({
              editId: id,
              mode: 'post-edit'
            })
            this.props.adminProductUpdateGet({id: id});
          }}
          onDelete={(id) => this.setState({ deleteId: id })}
        />
        </React.Fragment>
        }
        {mode === 'post-create' &&
        <AdminPostForm
          data={null}
          onCancel={() => this.setState({ mode: 'post-list' })}
        />
        }
        {mode === 'post-edit' &&
        <AdminPostForm
          data={{title: 'post title' }}
          onCancel={() => this.setState({ mode: 'post-list' })}
        />
        }
        {deleteId &&
        <Dialog
          isOpen={true}
          close={() => this.setState({ deleteId: null })}
          type="delete"
          title="Delete post"
          message="Are you sure want to delete this post"
          deleteButtonText="Delete post"
          onDelete={() => {
            this.props.adminProductUpdateDelete({"id": deleteId});
            this.setState({ deleteId: null });
          }}
        />
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    product_updates: state.Admin.get("product_updates"),
    product_updates_working: state.Admin.get("product_updates_working"),
  }),
  {
    adminProductUpdateDelete,
    adminProductUpdateGet,
  }
)(AdminProductUpdates);
