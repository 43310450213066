import React, { Component } from 'react'
import { connect } from "react-redux";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap"
import { logoMark } from "../../../assets/images"
import adminActions from "../../../redux/admin/actions";

const {
  adminGetUserAll,
} = adminActions;

class AdminUsers extends Component {

  componentDidMount = () => {
    this.props.adminGetUserAll();
  }

  render() {
    return (
      <div className="admin admin-users">
        <Table>
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>External User</th>
              <th>Is Admin</th>
            </tr>
          </thead>
          <tbody>
            {this.props.users?.map(item => (
              <tr key={`user-row-${item.id}`}>
                <td>
                  <div className="hstack gap-2">
                    <div
                      className="community-profile-info-avatar"
                      style={item?.profile_image && { backgroundImage: `url(${item?.profile_image})`}}
                    >
                      {item?.profile_image ?
                      <img src={item?.profile_image} alt={item?.username} />
                      :
                      <span className="icon icon-user" title={item?.username} />
                      }
                    </div>
                    <div>
                      <span>{item?.username}</span>
                      {!item?.is_external_user && <img src={logoMark} alt="AccelerList User" height={18} className="ms-1" />}
                    </div>
                  </div>
                </td>
                <td>{item?.email}</td>
                <td>{item?.first_name}</td>
                <td>{item?.last_name}</td>
                <td>{item.is_external_user
                    ? <span className="text-success"><b>YES</b></span>
                    : <span className="text-danger"><b>NO</b></span>
                }</td>
                <td>{item.is_admin
                    ? <span className="text-success"><b>YES</b></span>
                    : <span className="text-danger"><b>NO</b></span>
                }</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination className="mt-5" listClassName="justify-content-center" size="sm">
          <PaginationItem disabled>
            <PaginationLink>Previous</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink>Next</PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    users: state.Admin.get("users"),
    users_working: state.Admin.get("users_working"),
  }),
  {
    adminGetUserAll,
  }
)(AdminUsers);
