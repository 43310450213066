import React, { Component } from "react"
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input, Label } from "reactstrap"
import authActions from "../../redux/auth/actions";

const { userLogin } = authActions;

class LoginForm extends Component {
  state = {
    un: "",
    pw: "",
    showPassword: false
  }

  updateValue = (key, data) => {
    this.setState({ [key]: data });
  }

  login = () => {
    const { un, pw } = this.state;
    this.setState({ pw: ""})
    const data = {
      username: un,
      password: pw,
    }
    this.props.userLogin(data);
  }

  render() {
    const { showPassword } = this.state

    return (
      <Form>
        <FormGroup>
          <Label>Username</Label>
          <Input
            type="text"
            placeholder="Username"
            onChange={e => this.updateValue("un", e.target.value)}
            value={this.state.un}
            autoComplete="username"
          />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <div className="position-relative">
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              onChange={e => this.updateValue("pw", e.target.value)}
              value={this.state.pw}
              autoComplete="current-password"
            />
            <span
              className={`icon icon-${showPassword ? 'visible' : 'invisible'} position-absolute top-50 end-0 translate-middle`}
              role="button"
              onClick={() => this.setState({ showPassword: !showPassword })}
            />
          </div>
        </FormGroup>
        <div className="d-flex flex-column gap-3 text-center">
          <Button
            color="primary"
            onClick={() => this.login()}
            disabled={this.props.login_working}
          >Login to community</Button>
          <a href="https://app.accelerlist.com/forget_password" target="_blank" rel="noreferrer noopenner">Forgot password</a>
        </div>
      </Form>
    )
  }
}

export default connect(
  state => ({
    login_failed: state.Auth.get("login_failed"),
    login_failed_message: state.Auth.get("login_failed_message"),
    login_working: state.Auth.get("login_working"),
  }),
  {
    userLogin,
  }
)(LoginForm);
