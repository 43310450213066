import { Map } from "immutable";
import actions from "./actions";

const initState = new Map({
	userData: {
		username: null,
	},
  updating_user_settings: false,
  amazon_auth_working: false,
  updating_user_data: false,
  user_billing: [],
  user_billing_working: false,
  cards: [],
  user_data_loaded: false,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {

    case "USER_SAVE_DATA":
      return state
        .set("updating_user_data", true)
    case "USER_SAVE_DATA_SUCCESS":
      return state
        .set("userData", {...state.get("userData"), ...action.data?.data?.data})
        .set("updating_user_data", false)
    case "USER_SAVE_DATA_FAILED":
      return state
        .set("updating_user_data", false)

    case "USER_AMAZON_AUTH_START":
      return state
        .set("amazon_auth_working", true)
    case "USER_AMAZON_AUTH_START_SUCCESS":
      return state
        .set("amazon_auth_working", false)
    case "USER_AMAZON_AUTH_START_FAILED":
      return state
        .set("amazon_auth_working", false)

    case "USER_UPDATE_SETTINGS":
      var user_settings = state.get("userData");
      user_settings.settings = {
        ...user_settings.settings,
        ...action.data
      }
      return state
        .set("userData", {...user_settings})
        .set("user_settings_loading", true)
    case "USER_UPDATE_SETTINGS_SUCCESS":
      var user_settings_success = state.get("userData");
      user_settings_success.settings = {
        ...user_settings_success.settings,
        ...action.data.data
      }
      return state
        .set("userData", {...user_settings_success})
        .set("user_settings_loading", false)
    case "USER_UPDATE_SETTINGS_FAILED":
      return state
        .set("user_settings_loading", false)

		case actions.USER_SET_DATA_SUCCESS:
			return state
				.set("userData", Object.assign({}, action.data?.data))

		case actions.USER_GET_DATA_SUCCESS:
			return state
				.set("userData", Object.assign({}, action.data?.data?.data))
				.set("user_data_loaded", true)

		case actions.USER_GET_DATA_ERROR:
			return state
				.set("user_data_loaded", true)

		default:
			return state;
  }
}
