import Admin from "./admin/reducer";

import Front from "./front/reducer";
import User from "./user/reducer";
import Auth from "./auth/reducer";
import Community from "./community/reducer";

const Reducers = {
  Front,
  User,
  Auth,
  Community,
  Admin,
};

export default Reducers;
