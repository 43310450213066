export const appConfig = {
	environments: {
		"production": "production",
		"development": "development",
	},

	backendApiUrl: {
    "production": "https://back-u7wk.onrender.com/api/v1.0",
		"development": "http://127.0.0.1:5000/api/v1.0",
  },

	auth: {
		refreshTokenFrequency: 60000*15,
  },
}
