import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

class FeatureTabs extends Component {
  state = {
    activeTab: 0
  }

  render() {
    const { data } = this.props;

    return (
      <div className="feature-tabs">
        <Nav pills>
          {data?.tabs.map((item, idx) => (
          <NavItem key={`nav-item-${idx}`}>
            <NavLink
              active={this.state.activeTab === idx}
              onClick={() => this.setState({ activeTab: idx })}
            >{item}</NavLink>
          </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          {data?.contents.map((item, idx) => (
          <TabPane key={`tab-pane-${idx}`} tabId={idx}>
            {item.description}
            <img src={item.img_url} alt="" className="img-fluid" />
          </TabPane>
          ))}
        </TabContent>
      </div>
    )
  }
}

export default FeatureTabs;