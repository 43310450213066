import React, { Component } from 'react'
import { connect } from "react-redux";
import { Helmet } from "react-helmet"
import { PopoverBody, UncontrolledPopover } from "reactstrap"
import FeatureRequest from "./FeatureRequest"
import communityActions from "../../redux/community/actions";
import { Link } from "react-router-dom";

const {
  roadmapGetRoadmaps,
} = communityActions;

class CommunityRoadmap extends Component {

  componentDidMount = () => {
    this.props.roadmapGetRoadmaps();
  }

  render() {
    const { feature_roadmap } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Feature Roadmap | AccelerList</title>
          <meta name="description" content="AccelerList product feature roadmap" />
        </Helmet>
        <div className="inner-page-header">
          <h1 className="inner-page-title">Feature Roadmap</h1>
        </div>
        <div className="inner-page-content">
          <div className="roadmap">
            <RoadmapBox
              title="Under Review"
              id="under-review"
              data={feature_roadmap["UNDER_REVIEW"] || []}
            />
            <RoadmapBox
              title="In Progress"
              id="in-progress"
              data={feature_roadmap["IN_PROGRESS"] || []}
            />
            <RoadmapBox
              title="Complete"
              id="complete"
              data={feature_roadmap["COMPLETE"] || []}
            />
          </div>
        </div>

        {/* FEATURE REQUEST */}
        <div className="inner-page-header mt-5">
          <h2 className="inner-page-title">Feature Request</h2>
        </div>
        <div className="inner-page-content">
          <FeatureRequest />
        </div>
      </React.Fragment>
    )
  }
}

const RoadmapBox = ({ title, id, data }) => (
  <div className="roadmap-item-wrapper" id={id}>
    <h2 className="roadmap-title">{title}</h2>
    <div className="roadmap-list-scrollable">
      <ul className="roadmap-list">
        {data.map(item => (
          <li className="roadmap-item" key={`${id}-${item.id}`} id={`${id}-${item.id}`}>
            <Link to={`/community/feature-roadmap/requests/${item.slug}`}>
              <h3 className="roadmap-item-title">{item.title}</h3>
            </Link>
            <span className="roadmap-item-votes">{item.upvotes ? item.upvotes : 0} votes</span>
            <UncontrolledPopover trigger="hover" target={`${id}-${item.id}`} placement="top">
              <PopoverBody>
                <small>{item.description.length > 160 ? `${item.description.substring(0, 160)}...` : item.description}</small>
              </PopoverBody>
            </UncontrolledPopover>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

export default connect(
  state => ({
    userData: state.User.get("userData"),
    feature_roadmap: state.Community.get("feature_roadmap"),
    feature_roadmap_loading: state.Community.get("feature_roadmap_loading"),
  }),
  {
    roadmapGetRoadmaps,
  }
)(CommunityRoadmap)
